import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';




@Injectable({
  providedIn: 'root'
})
export class ExerciseService extends BaseService {
  constructor(protected override http: HttpClient,  private userService: UserService, protected override router: Router) {
    super(http, router);
  }

  bookAttrs(bis_type: string) {
    return super.get(`/courseware/book/attribute?bis_type=${bis_type}`);
  }
  list(resource_type: string, query: any) {
    const url = `/${resource_type}/list`;
    let queryStr = '';
    if (query) {
      queryStr = `?${this.objectToQueryString(query)}`;
    }
    return super.get(`${url}${queryStr}`);
  }



  save(resource_type: string, data: any) {
    return super.post( `/${resource_type}/data/save`, data);
  }
  saveQuestion(resource_type: string, data: any) {
    return super.post( `/${resource_type}/question/save`, data);
  }
  fetch(resource_type: string, id: number) {
    return super.get(`/${resource_type}/item/${id}`);
  }

  deleteQuestion(resource_type: string, id: number) {
    return super.remove(`/${resource_type}/item/${id}`);
  }


}
