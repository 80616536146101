<common-top-tool-bar [title]="this.book_id ? '编辑' : '新增'+'绘本 Reading'"
                     [back]="'/admin/v3/resource/'+bisType+'/edit/'+book_id"
                     [query]="{tabIndex: 1}"
                     [actionsTemplate]="actions">
  <ng-template #actions>
    <button nz-button [nzType]="'primary'" class="mr-4"
            [nzSize]="'large'" nzGhost
            [queryParams]="{ tabIndex: 1 }"
            [routerLink]="'/admin/v3/resource/'+bisType+'/edit/'+book_id+'/index'">
      取消
    </button>
    <button nz-button [nzType]="'primary'"
            [nzSize]="'large'"
            (click)="saveCoursewarePageRegion()">
      {{this.book_id ? '保存' : '确定新增' }}
    </button>
  </ng-template>
</common-top-tool-bar>





<div class="page-main">
  <nz-spin [nzSpinning]="isLoading">
    <div class="page-main-table">
      <div class="table-header"></div>
      <nz-tabset nzType="line"
                 [nzTabPosition]="'left'"
                 [nzSelectedIndex]="currentPageIndex"
                 (nzSelectedIndexChange)="pageIndexChange($event)">

<!--        cdkDropList (cdkDropListDropped)="drop($event)">-->
<!--        <tr *ngFor="let data of dataSet" cdkDrag>-->

        <nz-tab *ngFor="let page of factory_pages;
                let i = index" [nzClosable]="false"
                [nzTitle]="titleTemplate">

          <ng-template #titleTemplate>
            <div style="font-size: 14px">
              <div class="px-2"></div>
              {{ '頁碼'+(i + 1) }}
            </div>
          </ng-template>
          <ng-template nz-tab>
<!--            <touch-to-read-config-->
<!--              [imgWidth]="page.image_area.images[0].img_width"-->
<!--              [imgHeight]="page.image_area.images[0].img_height"-->
<!--              [imageUrl]="page.image_area.images[0].imageUrl"-->
<!--              [hotzones]="page?.image_area.images[0].hotzones"-->
<!--              [currentRegionIndex]="currentRegionIndex"-->
<!--              (anchorRegionEvent)="anchorRegion($event)"-->
<!--              (addImageRegionEvent)="addImageRegion($event)"-->
<!--              (dropEvent)="drop($event)"-->
<!--              (removeRegionEvent)="removeRegion($event)"-->
<!--              (clearRegionAudioEvent)="clearRegionAudio($event)"-->
<!--              (onAudioUploadFailureEvent)="onAudioUploadFailure($event)"-->
<!--              (onAudioUploadSuccessEvent)="onAudioUploadSuccess($event)"-->
<!--            ></touch-to-read-config>-->
          <div class="p-8" style="padding-left: 8px;">
            <div nz-row>
              <div nz-col [nzSpan]="getPicAspectRatio(page) > 1 ? 16 : 8">
                <nz-affix [nzOffsetTop]="150"  #affix>
                <div
                  *ngIf="page.image_area.images[0].img_width && page.image_area.images[0].img_height"
                  #containerRef
                  id="boundingContainerDiv"
                  class="w-full h-auto img-editor overflow-hidden relative"
                  [ngStyle]="{
                  'width': 100+'%',
                  'aspect-ratio': getPicAspectRatio(page),
                  'background-image': 'url(' + (page.image_area.images[0].imageUrl | mediaUrlPipe) + ')'}"
                >
<!--                  page.image_area.images[0].img_width / page.image_area.images[0].img_height ,-->

                  <div
                    *ngFor="let region of (page?.image_area.images[0].hotzones ||[]); let j = index"
                    (click)="anchorRegion($event, j)"
                    draggableElement
                    resizableElement
                    [container]="containerRef"
                    [(region)]="region.rect"
                    class="region-handler"
                    style="position: absolute"
                    [enable]="j == this.currentRegionIndex"
                    [ngClass]="{active: j == this.currentRegionIndex}"
                  >
                    <div class="region-handler-ident">{{j + 1}}</div>
                  </div>
                </div>
                </nz-affix>
              </div>
              <div nz-col [nzSpan]="getPicAspectRatio(page) > 1 ? 8 : 16" style="padding-left: 32px">
                <div class="main-text-color font-bold text-lg mb-4">标记音频对应位置</div>
                <div class="text-slate-400 font-light text-xs mb-4">注：请用图形工具框出每句的范围位置，一个方框对应一个音频文件。</div>
                <button nz-button [nzType]="'primary'" class="mr-4 mb-8"
                        (click)="addImageRegion()"
                        [nzLoading]="isAiLoading"
                        [nzSize]="'default'" nzGhost>
                  <span nz-icon  nzType="edit" nzTheme="fill" class="fix-icon"></span>
                  新增点读区域
                </button>
                <button nz-button [nzType]="'primary'" class="mr-4 mb-8"
                        (click)="getDpeCoursewarePageInfo()"
                        [nzLoading]="isAiLoading"
                        [nzSize]="'default'" nzGhost>
                  <span nz-icon  nzType="robot" nzTheme="fill" class="fix-icon"></span>
                  AI识别
                </button>
                <div (cdkDropListDropped)="drop($event)" cdkDropList cdkDropListOrientation="vertical">
                  <div *ngFor="let region of page.image_area.images[0].hotzones; let k = index" cdkDrag class="bg-white">
                    <div class="flex p-4" [id]="'regionCfg'+k">
<!--                      [ngClass]="{'bg-amber-100': k == currentRegionIndex}"-->
                      <div class="flex-1" style="width: 100%;">
                        <div class="main-text-color font-bold text-lg mb-4 flex">
                          <div class="flex-1">区域{{k + 1}}</div>
                          <div class="flex-none">
                            <span nz-icon class="fix-icon" style="color: #1890FF;cursor: pointer;"
                                  (click)="anchorRegion($event, k)"
                                  nzType="link"
                                  nzTheme="outline"></span>
                            <span nz-icon nzType="pause" nzTheme="outline" class="sort-handler" cdkDragHandle></span>
                            <span nz-icon style="color: #1890FF;cursor: pointer;"
                                  class="fix-icon"
                                  (click)="removeRegion($event, k)"
                                  nzType="close"
                                  nzTheme="outline"></span>
                          </div>
                        </div>


<!--                        nzShowSearch nzAllowClear-->
                        <nz-select class="w-full"
                                   [ngModel]="region.relatedParagraphId"
                                   (ngModelChange)="changeRegionRef($event, k)"
                                   nzPlaceHolder="Select a person" #textRef>
                          <nz-option nzLabel="自定义" [nzValue]="-1"></nz-option>
                          <nz-option nzCustomContent [nzLabel]="t.text" [nzValue]="t.id" *ngFor="let t of page.text_area.paragraphs">
                            <div class="font-thin">
                              <div class="text-ellipsis">{{t.text}}</div>
                              <!-- {{t.text}} -->
                              <hr>
                              <!-- {{t.cnTranslation}} -->
                              <div class="text-ellipsis">{{t.cnTranslation}}</div>
                            </div>
                          </nz-option>
                        </nz-select>

                        <div *ngIf="region.relatedParagraphId === -1">
                          <div>英文：<a nz-button nzType="link" style="float: right" [nzSize]="'small'" [nzLoading]="transBtn.nzLoading" (click)="translate($event, k, transBtn)" #transBtn>翻译</a></div>
  <!--                        <textarea rows="3" nz-input [(ngModel)]="(region.relatedParagraphId == -1 ? region: regionParagraphText(i, k, region.relatedParagraphId)).text " class="mb-4"></textarea>-->
                          <textarea rows="3" nz-input [(ngModel)]="region.text" class="mb-4"></textarea>
                          <div>中文：</div>
  <!--                        <textarea rows="3" nz-input [(ngModel)]="page.text_area.paragraphs[k].cnTranslation" class="mb-4"></textarea>-->
  <!--                        <textarea rows="3" nz-input [(ngModel)]="(region.relatedParagraphId == -1 ? region: regionParagraphTextTranslation(i, k, region.relatedParagraphId)).cnTranslation " class="mb-4"></textarea>-->
                          <textarea rows="3" nz-input [(ngModel)]="region.cnTranslation" class="mb-4"></textarea>
                          <div>音频：</div>
  <!--                          [audioUrl]="(region.relatedParagraphId == -1 ? region: regionParagraphTextTranslation(i, k, region.relatedParagraphId)).audio_url"-->
                          <tw-audio-uploader
                            [audioUrl]="region.audioUrl"
                            [audioName]="region.audioName"
                            comment="注：音频格式为 MP3 文件，音频文件大小限制为 10MB。"
                            (clear)="clearRegionAudio($event, k)"
                            (audioUploadFailure)="onAudioUploadFailure($event, k)"
                            (audioUploaded)="onAudioUploadSuccess($event, k)">
                          </tw-audio-uploader>
                          <!-- <audio *ngIf="region.audioUrl" controls class="mt-2 w-full" (timeupdate)="checkAudioTime($event, k)">
                            <source [src]="region.audioUrl" type="audio/mpeg">
                            您的浏览器不支持音频播放
                          </audio> -->
                          <audio *ngIf="region.audioUrl" controls class="mt-2 w-full">
                            <source [src]="region.audioUrl" type="audio/mpeg">
                            您的浏览器不支持音频播放
                          </audio>
                        </div>
                      </div>

                    </div>

                    <nz-divider></nz-divider>
                  </div>

                </div>

              </div>
            </div>
          </div>

          </ng-template>
        </nz-tab>
      </nz-tabset>
    </div>
  </nz-spin>
</div>



