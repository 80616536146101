<common-top-tool-bar [title]="pageTitle"
                     [back]="'/admin/v3/resource/'+bisType+'/edit/'+book_id"
                     [query]="{tabIndex: 1}"
                     [actionsTemplate]="actions">
  <ng-template #actions>
    <button nz-button [nzType]="'primary'" class="mr-4"
            [nzSize]="'large'" nzGhost
            [routerLink]="'/admin/v3/resource/'+bisType+'/courseware'">
      取消
    </button>
    <button nz-button [nzType]="'primary'"
            [nzSize]="'large'"
            (click)="save($event)">
      {{this.book_id ? '儲存' : '確定新增' }}
    </button>
  </ng-template>
</common-top-tool-bar>




<div class="page-main">
  <nz-spin [nzSpinning]="isLoading">
    <div class="page-main-header">
      <div class="page-main-header-tools">
        <button nz-button nzType="primary" nzGhost (click)="showAudioResourceDialog()">
          <i nz-icon nzType="plus" nzTheme="outline" class="fix-icon" ></i>
          添加音频
        </button>
      </div>
      <div class="page-main-header-actions">
        <button nz-button nzType="default" nzDanger class="mr-4">删除此页</button>
      </div>
    </div>
    <div class="page-main-wrapper" style="padding: 0 2rem;">
    <div class="page-main-table">
      <div class="table-header"></div>
      <div>


        <nz-table #basicTable
                  [nzHideOnSinglePage]="true"
                  nzSize="small"
                  [nzData]="itemSelectionValues">
        <thead>
        <tr>
          <th>Name</th>
          <th [width]="100">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of basicTable.data">
          <td>{{item.name}}</td>
          <td>
            <a (click)="removeSelectAudio(item)">Delete</a>
          </td>
        </tr>
        </tbody>
        </nz-table>



      </div>
      <input nz-input [(ngModel)]="audio_relate" placeholder="large size" nzSize="large" />
    </div>
    </div>
  </nz-spin>
</div>

<ng-template #modalContent>
  <nz-spin [nzSpinning]="false">
    <app-admin-book-resource-courseware-list
      [resourceType]="'audio'"
      [(itemSelection)]="itemSelection"></app-admin-book-resource-courseware-list>
  </nz-spin>
</ng-template>
<ng-template #modalFooter>
  <div>
    <button nz-button [nzType]="'primary'" [nzSize]="'default'" (click)="audioSelected()">
      选择
    </button>
    <button nz-button [nzType]="'primary'" [nzSize]="'default'" nzGhost (click)="closeAudioSelectDialog()">关闭
    </button>
  </div>
</ng-template>
<nz-modal
  [nzWidth]="'80%'"
  nzWrapClassName="v3"
  [nzKeyboard]="false"
  [nzMaskClosable]="false"
  [nzClosable]="false"
  [(nzVisible)]="showAudioSelectDialog"
  [nzContent]="modalContent"
  [nzTitle]="null"
  [nzFooter]="modalFooter"
  (nzOnCancel)="closeAudioSelectDialog()"
>


</nz-modal>
