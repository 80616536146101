import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { en_US } from 'ng-zorro-antd/i18n';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { TwNoticeService } from '../../../../../../services/tw-notice.service';
import { Observable, Observer } from 'rxjs';
import { SyllabusService } from '../../../../../../services/syllabus.service';
import { ThemeService } from '../../../../../../services/theme.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-admin-media-category-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class AdminMediaCategoryIndexComponent implements OnInit, AfterViewInit {

  isLoading = false;
  isAddCategoryVisible = false;
  isDeleteVisible = false;
  newThemeCategoryVisible = false;
  tabIndex = 0;
  userInfo: any = {};

  tabs: any = [];
  themes: any = [];
  rootCate = {id: 0, name: ''};
  // childCate = {id: 0, name: ''};
  // cateForm!: UntypedFormGroup;
  // themeForm!: UntypedFormGroup;

  //
  itemForm!: UntypedFormGroup;
  editItem = {id: 0, pid: 0, name: ''};
  cateItem = {id: 0, pid: 0, name: '', children: []};
  formDialogVisible = false;
  cateTabIndex = 0;

  // themeCategoryForm!: UntypedFormGroup;
  tableScroll = 500;
  isEdit = false;
  addPid = 0;
  title = '';
  formFields: string[] = [];
  bisType = '';

  @ViewChild('basicTable', {static: true}) basicTable: ElementRef | undefined;
  @ViewChildren('affix') affixs!: ElementRef[];

  constructor(private nzContextMenuService: NzContextMenuService,
              private modalService: NzModalService,
              private syllabusService: SyllabusService,
              private themeService: ThemeService,
              private twNoticeService: TwNoticeService,
              private activatedRoute: ActivatedRoute ,
              private fb: UntypedFormBuilder,
              private router: Router ,
              private elRef: ElementRef,
              private nzMessageService: NzMessageService) {
    // this.userInfo = this.userService.getUserInfo();
    // console.log('info', this.userInfo);
    // this.checkSubsidyModelOpen.subscribe((evt) => {
    //   console.log('modal open');
    // });

    // @ts-ignore
    // let version = this.router.browserUrlTree.root.children.primary?.segments[1]?.path;
    // @ts-ignore
    this.bisType = this.router.browserUrlTree.root.children.primary?.segments[3]?.path;
    console.log('[category] index', this.bisType);
    if (this.bisType === 'audio') {
      this.title = '音频';
    }
    if (this.bisType === 'video') {
      this.title = '视频';
    }
    // this.cateForm = this.fb.group({
    //   id: [null ],
    //   pid: [0],
    //
    //   name: ['', [ Validators.required ], [ this.cateNameAsyncValidator ] ],
    //   description: [''],
    //   bis_type: [this.bisType],
    //   cover: [''],
    // });
    // this.themeForm = this.fb.group({
    //   id: [null ],
    //   pid: [0],
    //   bis_type: [this.bisType],
    //   name: ['', [ Validators.required ], [ this.themeNameAsyncValidator ] ],
    //   // description: [''],
    //   cover: [''],
    // });
    this.itemForm = this.fb.group({
      id: [null ],
      pid: [0],
      syllabus_id: [0],
      bis_type: [this.bisType],
      name: ['', [ Validators.required ], [ this.themeNameAsyncValidator ] ],
      // description: [''],
      cover: [''],
    });
    // @ts-ignore
    window.aaa = this;
  }
  afterModalClose() {

  }




  handleDeleteCancel() {
    this.isDeleteVisible = false;
  }
  handleViewDelete(row: any) {
    this.isDeleteVisible = true;
  }

  ngAfterViewInit() {
    // this.tableScroll = {y: window.innerHeight - 48 - 56 - 72 - 54 - 64 - 136}
    // window.tableScroll = this.tableScroll;
    // window.basicTable = this.basicTable
    this.tableScroll =  window.innerHeight  - 56 - 72 - 54 - 64 ;
    console.log('tableScroll', this.tableScroll);
  }
  ngOnInit() {
    console.log(11111);
    this.loadCateData();
  }

  openItemDialog(item: any, title: string, fields= [], $event?: any) {
    $event?.stopPropagation();
    console.log('openItemDialog', item);
    this.editItem = item;
    this.itemForm.reset();
    this.title = title;
    this.formDialogVisible = true;
    this.formFields = fields;
    if (item.id && item.id !== '' && +item.id > 0 && !isNaN(+item.id)) {
      this.isEdit = true;
    }
    for (const name in this.itemForm.controls) {
      // @ts-ignore
      this.itemForm.controls[name].setValue(item[name] || '');
    }
  }
  closeItemDialog() {
    console.log('closeItemDialog');
    this.formDialogVisible = false;
    this.itemForm.reset();
    this.title = '';
    // this.editItem = {id: 0, pid: 0, name: ''};
    this.isEdit = false;
  }




  themeNameAsyncValidator = (control: UntypedFormControl) => new Observable((observer: Observer<ValidationErrors>) => {
    console.log('control', control.dirty,  control.value);
    // @ts-ignore
    // if (this.cate.name === control.value) {
    if (!control.dirty) {
      // @ts-ignore
      observer.next(null);
      observer.complete();
      return;
    }

    const data = {
      id: this.itemForm.controls['id'].value || 0,
      pid: this.itemForm.controls['pid'].value || 0,
      bis_type: this.bisType,
      name: control.value
    };
    if (this.itemForm.controls['syllabus_id'].value) {
      // @ts-ignore
      data['syllabus_id'] = this.itemForm.controls['syllabus_id'].value;
    }

    this.themeService.checkNameExist(data).then(resp => {
      // console.log(99, resp);
      if (resp && resp.length) {
        observer.next({ error: true, duplicated: true });
      } else {
        // @ts-ignore
        observer.next(null);
      }
      observer.complete();
    });
  })

  async loadCateData() {
    this.isLoading = true;

    try {
      const data = await this.syllabusService.get(`/syllabus/${this.bisType}/category`);
      this.isLoading = false;
      this.tabs = data;
    } catch (err) {
      console.log(err);
      this.isLoading = false;
    }
  }
  async loadTagsData () {
    this.isLoading = true;

    this.themeService.get(`/tags/${this.bisType}/tree`).then(data => {
      this.isLoading = false;
      const _themes: any = {};
      data.forEach((item: any) => {
        if (!_themes[item.syllabus_id]) {
          _themes[item.syllabus_id] = [];
        }
        _themes[item.syllabus_id].push(item);
      });
      this.themes = _themes;
    }).catch((err: any) => {
      console.log(err);
      this.isLoading = false;
    });
  }

  tabIndexChange(index: number) {
    console.log('tabIndexChange', index);
    this.tabIndex = index;
    const hasThemeData = this.themes && Array.isArray(this.themes) && this.themes.length > 0;
    if (index === 1 && !hasThemeData ) {
      this.loadTagsData();
    }


  }
  cateIndexChange(index: number) {
    console.log('cateIndexChange', 1, index);
    // this.rootCate = this.tabs[index];
    this.cateTabIndex = index;
    this.cateItem = this.tabs[index];
    const affix = this.affixs.find((_, i) => i === index );
    if (affix) {
      // @ts-ignore
      // affix.ngAfterViewInit();
    }
  }
  newThemeCategory(data: any) {
    console.log('newThemeCategory', data);
    this.newThemeCategoryVisible = true;
    this.title = '主题分类';
  }
  submitItemForm = ($event: any, value: any) => {
    $event.preventDefault();
    if (this.isLoading) {
      return;
    }
    if (!value.cover) {
      delete value.cover;
    }

    value.seq = 0;
    value.bis_type = this.bisType;
    let svc = this.syllabusService;
    let loadFn = this.loadCateData.bind(this);
    if (this.tabIndex === 1) {
      // @ts-ignore
      svc = this.themeService;
      loadFn = this.loadTagsData.bind(this);
      value.pid = this.editItem.pid;
    } else {
      value.pid = this.editItem.pid;
    }

    if (!value.pid) {
      value.pid = 0;
      value.root = 1;
      value.depth = 1;
    } else {
      value.root = 0;
      value.depth = 2;
    }


    console.log('submitItemForm', value);
    this.isLoading = true;
    svc.save(this.bisType, value).then((res: any) => {
      this.twNoticeService.success({
        nzContent: `${value.id ? '編輯' : '新增'}成功`,
      });
      this.closeItemDialog();
      this.isLoading = false;
      loadFn();
    }).catch((err: any) => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System error');
    });
  }
  removeItem(item: any) {
    // this.isAddCategoryVisible = false;

    let svc = this.syllabusService;
    // let form = this.cateForm;
    const form = this.itemForm;
    let loadFn = this.loadCateData.bind(this);
    if (this.tabIndex === 1) {
      // @ts-ignore
      svc = this.themeService;
      // form = this.themeForm;
      loadFn = this.loadTagsData.bind(this);
    }
    console.log('removeItem', form.controls);
    const modal = this.twNoticeService.confirm({
      nzTitle: `是否刪除「${form.controls['name'].value}」${this.title}？`,
      nzContent: '確定刪除後將無法復原，是否仍執行此動作？',
      nzOnOk: (e: any) => {
        // @ts-ignore
        svc.deleteItem( {id: form.controls['id'].value}).then((res: any) => {
          this.formDialogVisible = false;
        this.twNoticeService.success({
          nzContent: `刪除「${form.controls['name'].value}」${this.title}`,
        });
          modal.close();
          loadFn();
        }).catch((err: { message: any; }) => {
          this.nzMessageService.error(err.message || 'System error');
        });
      }
    });
  }



  submitThemeCategoryForm = ($event: any, value: any) => {
    $event.preventDefault();
    if (this.isLoading) {
      return;
    }
    if (!value.cover) {
      delete value.cover;
    }
    value.syllabus_id = this.rootCate.id;
    value.syllabus_id = this.cateItem.id;
    value.bis_type = this.bisType;
    // let svc = this.syllabusService;
    // const loadFn = this.loadCateData.bind(this);
    // if (this.tabIndex == 1) {
    //   // @ts-ignore
    //   svc = this.themeService;
    const  loadFn = this.loadTagsData.bind(this);
    // } else {
    //
    // }
    value.pid = this.addPid;

    console.log('submitThemeCategoryForm', value);
    this.isLoading = true;
    this.themeService.save(value).then((res: any) => {
      this.twNoticeService.success({
        nzContent: `${value.id ? '編輯' : '新增'}成功`,
      });
      this.newThemeCategoryVisible = false;
      this.isEdit = false;
      // this.themeCategoryForm.reset();
      this.isLoading = false;
      loadFn();
    }).catch((err: any) => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System error');
    });
  }

  coverUploaded($event: any) {
    console.log('coverUploaded', $event);
    // this.isAddCategoryVisible = true;
    // let form = this.cateForm;
    // if (this.tabIndex == 1) {
    //   form = this.themeForm;
    // }
    // form.controls['cover'].setValue($event.url || '');
    this.itemForm.controls['cover'].setValue($event.url || '');

  }
  sortCate($event: any) {
    if ($event.previousIndex === $event.currentIndex) {
      return;
    }
    moveItemInArray(this.tabs, $event.previousIndex, $event.currentIndex);
    const data = this.tabs.map((item: any, index: number) => {
      return [item.id, index];
    });
    console.log('drop', data);
    this.isLoading = true;
    return this.syllabusService.seq(this.bisType, data).then((res: any) => {
      this.isLoading = false;
      this.loadCateData();
      // this.themes.length = 0;
    }).catch((err: any) => {
      console.log(err);
      this.nzMessageService.error(err.message || 'System Error');
      this.isLoading = false;
    });
  }
  sortSubCate($event: any){
    if ($event.previousIndex === $event.currentIndex) {
      return;
    }
    // const cates = this.tabs[this.cateTabIndex];
    // console.log(cates);
    // console.log(this.cateItem);
    const {children} = this.cateItem;
    moveItemInArray(children, $event.previousIndex, $event.currentIndex);
    const data = children.map((item: any, index: number) => {
      return [item.id, index];
    });
    console.log('drop', data);
    this.isLoading = true;
    return this.syllabusService.seq(this.bisType, data).then((res: any) => {
      this.isLoading = false;
      this.loadCateData();
      // this.themes.length = 0;
    }).catch((err: any) => {
      console.log(err);
      this.nzMessageService.error(err.message || 'System Error');
      this.isLoading = false;
    });


  }
}
