import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { environment } from '../../../../../../environments/environment';


@Component({
  selector: 'app-class-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class TeachingIndexComponent implements OnInit {


  teachingbook = false;
  winHeight = 0;

  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private sanitizer: DomSanitizer,
              private nzMessageService: NzMessageService) {

  }

  ngOnInit() {
    console.log(window.btoa(this.userService.getUserInfo().id));
    console.log('environment.production', environment.production);
  }

  goCreate() {
    this.winHeight = document.body.offsetHeight - 200;
    const {id, school_id} = this.userService.getUserInfo()
    const uidBase = window.btoa(JSON.stringify({user_id: id, school_id}));
    // window.open('http://service01.ireadabc.com?busy=' + uidBase);
    let url = 'http://staging-teachingbook.ireadabc.com?busy=' + uidBase;
    if (environment.production) {
      url = 'http://teachingbook.ireadabc.com?busy=' + uidBase;
    }
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      url = 'http://127.0.0.1:1337/teachingbook?busy=' + uidBase;
    }
    console.log('goCreate', url);
    window.open(url);
  }

  goResource(resourceType: any) {

    this.router.navigate([`/admin/v3/resource/${resourceType}/list`]);
    // this.winHeight = document.body.offsetHeight - 200;
    // const uidBase = window.btoa(this.userService.getUserInfo().id);
    // // window.open(`http://service02.ireadabc.com?busy=${uidBase}&resource_type=${resourceType}`);
    // let url = `http://staging-teachingresource.ireadabc.com?busy=${uidBase}&resource_type=${resourceType}`;
    // if (environment.production) {
    //   url = `http://teachingresource.ireadabc.com?busy=${uidBase}&resource_type=${resourceType}`;
    // }
    // console.log('goResource', url);
    // window.open(url);
  }
  goSchoolTesting() {
    this.router.navigate([`/admin/v3/resource/exercise/list`]);
    // this.winHeight = document.body.offsetHeight - 200;
    // const uidBase = window.btoa(this.userService.getUserInfo().id);
    // const sidBase = window.btoa(this.userService.getUserInfo().school_id);
    // // window.open(`http://service02.ireadabc.com?busy=${uidBase}&resource_type=${resourceType}`);
    // let url = `http://staging-schooltesting.ireadabc.com?busy=${uidBase}&school=${sidBase}`;
    // if (environment.production) {
    //   url = `http://schooltesting.ireadabc.com?busy=${uidBase}&school=${sidBase}`;
    // }
    // console.log('goSchoolTesting', url);
    // window.open(url);
  }
}
