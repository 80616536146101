import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { UserStatus, UserType } from '../../../../../model/Const';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { CoursewareService } from '../../../../../services/courseware.service';
import { HWResourceService } from '../../../../../services/huawei-resource.service';
import { SyllabusService } from '../../../../../services/syllabus.service';
import { ExerciseService } from '../../../../../services/exercise.service';
import { MediaV2Service } from '../../../../../services/media-v2.service';


@Component({
  selector: 'app-admin-book-resource-courseware-list',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class AdminBookResourceCoursewareListComponent implements OnInit {
  dataSet: any[] = [];
  totalNum = 0;
  totalClasses = 0;
  currentPageIndex = 1;
  user: any = null;
  constUserType = UserType;
  isLoading = false;
  isLoadingAttr = false;
  pageTitle: string | undefined = '';

  bookAttrs: any = {};
  themeKeyValueItems: any = {};
  syllabusKeyValueItems: any = {};
  rootSyllabusThemes: any = [];
  subSyllabusThemes: any = [];

  currentSyllabusId = 0;
  currentCourseId = 0;
  indeterminate = false;

  // @Output() onItemSelected = new EventEmitter();
  // @Input() refPage: AdminBookResourceCoursewareListComponent|null = null;
  @Input() resourceType = '';

  @Input() itemSelection!: Map<string|number, any>;
  @Output() itemSelectionChange = new EventEmitter<Map<string|number, any>>();



  filter: any = {
    pageSize: 10,
    pageIndex: 1,
    name: '',
    syllabus_id: '',
    course_id: '',
    themes: {
    },
    reserve3: '',
    // created_on: '',
  };
  host = '';
  protocol = '';
  bucket = '';

  mainService!: ExerciseService|CoursewareService|MediaV2Service;


  // setOfCheckedId = new Set<number>();



  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private coursewareService: CoursewareService,
              private exerciseService: ExerciseService,
              private syllabusService: SyllabusService,
              private mediaService: MediaV2Service,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    const u = new URL(window.location.href);
    console.log('AdminBookResourceCoursewareListComponent');
    this.protocol = u.protocol;
    this.host = HWResourceService.cdnHost;
    // this.bucket = HWResourceService.bucketPath;

    this.activatedRoute.title.subscribe({
      next: (title) => {
        this.pageTitle = title;
      }
    });
    // @ts-ignore
    window.aaa = this;
  }
  updateCheckedSet(item: any, checked: boolean): void {
    if (!this.itemSelection) {
      return;
    }
    if (checked) {
      this.itemSelection.set(item.id, item);
    } else {
      this.itemSelection.delete(item.id);
    }
  }
  onItemChecked(item: any, checked: boolean): void {
    if (!this.itemSelection) {
      return;
    }
    this.updateCheckedSet(item, checked);
  }




  get titleName() {
    let title = '';
    if (this.resourceType === 'cartoon') {
      title = '绘本';
    } else if (this.resourceType === 'book') {
      title = '绘本';
    } else if (this.resourceType === 'audio') {
      title = '音频';
    } else if (this.resourceType === 'video') {
      title = '视频';
    } else if (this.resourceType === 'exercise') {
      title = '练习';
    }
    return title;
  }
  changeStatus($event: any, row: any ) {
    console.log('changeStatus', $event, row);
    let enabled = 0;
    if ($event) {
      enabled = 1;
    }
    row.enabled = enabled;
    row.reserve3 = enabled;
    console.log('changeStatus reserve3', enabled);
    this.isLoading = true;
    this.mainService.save(this.resourceType, {id: row.id, reserve3: enabled, enabled }).then(() => {
      this.isLoading = false;
    }).catch((err: any) => {
      this.nzMessageService.error(err.message || 'System Error');
      this.isLoading = false;
    });

  }
  changeCategory(syllabus_id: number) {
    this.filter.course_id = '';
    this.filter.syllabus_id = syllabus_id;
    if (!syllabus_id) {
      this.rootSyllabusThemes.length = 0;
      this.subSyllabusThemes.length = 0;
      return;
    }
    const a = this.syllabusKeyValueItems[syllabus_id];
    const root_themes = this.bookAttrs.themes.filter((s: any) => {
      return s.syllabus_id === syllabus_id;
    });

    console.log('chang syllabus', syllabus_id, root_themes);
    this.rootSyllabusThemes = root_themes;
  }

  changeSubCategory(course_id: number) {
    this.filter.course_id = course_id;
    if (!course_id) {
      this.subSyllabusThemes.length = 0;
      return;
    }
    const sub_themes = this.bookAttrs.themes.filter((s: any) => {
      return s.syllabus_id === course_id;
    });
    console.log('chang course', course_id, sub_themes);
    this.subSyllabusThemes = sub_themes;
  }


  changeSubTheme(theme_group_id: number, theme_id: number) {
    console.log('changeSubTheme', theme_group_id, theme_id);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    console.log('onQueryParamsChange', params);
    const { pageSize, pageIndex, sort, filter } = params;
    // const currentSort = sort.find(item => item.value !== null);
    // const sortField = (currentSort && currentSort.key) || null;
    // const sortOrder = (currentSort && currentSort.value) || null;
    this.filter = {...this.filter, ...params};
    this.loadData(this.filter);
  }
  ngOnInit() {
    if (!this.resourceType) {
      this.resourceType = this.activatedRoute.pathFromRoot[2].snapshot.url[2].path;
    }
    console.log('this.resourceType', this.resourceType);
    this.mainService = this.coursewareService;
    if (this.resourceType === 'video' || this.resourceType === 'audio') {
      this.mainService = this.mediaService;
      this.filter.pageSize = 10;
    } else if (this.resourceType === 'exercise') {
      this.mainService = this.exerciseService;
      this.filter.pageSize = 15;
    }
    this.isLoadingAttr = true;
    this.syllabusService.category(this.resourceType).then((bookAttrs: any) => {
      this.bookAttrs = bookAttrs;
      const _cateThemeKeyValueItems: any = {};
      bookAttrs.themes.forEach((theme: any) => {
        _cateThemeKeyValueItems[theme.id] = theme;
      });
      this.themeKeyValueItems = _cateThemeKeyValueItems;
      const _syllabusKeyValueItems: any = {};
      bookAttrs.syllabus.forEach((syllabus: any) => {
        _syllabusKeyValueItems[syllabus.id] = syllabus;
      });
      this.syllabusKeyValueItems = _syllabusKeyValueItems;
      console.log('ngOnInit', bookAttrs);
      this.isLoadingAttr = false;
    }).catch((err: any) => {
      this.isLoadingAttr = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
    this.loadData({...this.filter, pageIndex: 1});
  }
  doFilter($evt: any) {
    if (!$evt) {
      return;
    }
    console.log('doFilter', $evt, this.filter);
    this.filter = {...this.filter, pageIndex: 1};
    this.loadData(this.filter);
  }
  clearSearch() {
    this.filter.name = '';
    // this.loadData({pageIndex: 1});
  }
  loadData(query?: any) {
    console.log('loadData query', query);
    this.isLoading = true;
    this.mainService.list(this.resourceType, {...query}).then((result: any) => {
      console.log('loadData data', result);
      this.dataSet = result.rows.map((r: any) => {
        r.created_date = r.created_date.substring(0, 10);
        return r;
      });
      this.totalNum = result.total;
      this.isLoading = false;

    }).catch((err: any) => {
      this.nzMessageService.error(`錯誤：${err.message}`);
      this.isLoading = false;
    });

  }

}
