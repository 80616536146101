<div class="mt-3 mb-2" [@homeworkAnimation]>
  <span class="text-blue-400 text-sm font-semibold">作业{{ index + 1 }}</span>
</div>
<div *ngIf="loading" class="flex justify-center pt-2 pb-4">
  <nz-spin nzSimple></nz-spin>
</div>
<div class="flex gap-0.5" *ngIf="!loading" [@homeworkAnimation]>
  <!-- 作业内容 -->
  <div class="flex-1 border rounded py-6 px-10 flex flex-col" [formGroup]="form">
    <div>
      <!-- 作业类型 -->
      <nz-form-item>
        <nz-form-label class="w-20">作业类型</nz-form-label>
        <nz-form-control [nzValidateStatus]="getValidateStatus(form.get('type'))"
                         nzErrorTip="请选择作业类型"
                         class="w-72 flex-none">
          <nz-select formControlName="type" nzPlaceHolder="请选择作业类型">
            <nz-option *ngFor="let homeworkType of ResourcePushWeekTemplateHomeworkTypes"
                       [nzValue]="homeworkType.value"
                       [nzLabel]="homeworkType.label"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <!-- 教材名称 -->
      <nz-form-item>
        <nz-form-label class="w-20">教材名称</nz-form-label>
        <nz-form-control [nzValidateStatus]="getValidateStatus(form.get('level1ResourceId'))"
                         nzErrorTip="请选择教材名称"
                         class="w-72 flex-none">
          <nz-select
            nzShowSearch
            formControlName="level1ResourceId"
            nzPlaceHolder="请选择教材名称">
            <nz-option *ngFor="let level1Resource of level1Resources"
                       [nzValue]="level1Resource.id"
                       [nzLabel]="level1Resource.name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <!-- 教材级别 -->
      <nz-form-item>
        <nz-form-label class="w-20">教材级别</nz-form-label>
        <nz-form-control [nzValidateStatus]="getValidateStatus(form.get('level2ResourceId'))"
                         nzErrorTip="请选择教材级别"
                         class="w-72 flex-none">
          <nz-select
            nzShowSearch
            formControlName="level2ResourceId"
            nzPlaceHolder="请选择教材级别">
            <nz-option *ngFor="let level2Resource of level2Resources"
                       [nzValue]="level2Resource.id"
                       [nzLabel]="level2Resource.name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <!-- 多选课程 -->
      <nz-form-item *ngIf="isLevel3ResourceIds()">
        <nz-form-label class="w-20">选择课程</nz-form-label>
        <nz-form-control [nzValidateStatus]="getValidateStatus(form.get('level3ResourceIds'))"
                         nzErrorTip="请至少选择一门课程"
                         class="flex-1">
          <nz-select
            nzShowSearch
            nzMode="multiple"
            formControlName="level3ResourceIds"
            nzPlaceHolder="请选择课程">
            <nz-option *ngFor="let level3Resource of level3Resources"
                       [nzValue]="level3Resource.id"
                       [nzLabel]="level3Resource.name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <!-- 单选课程列表 -->
    <div *ngIf="isLevel3ResourceArray() && form.get('level2ResourceId')?.value"
         class="pl-20">
      <!-- 添加课程按钮 -->
      <div>
        <button nz-button
                nzType="primary"
                (click)="handleAddLevel3ResourceAdd()">
          添加课程
        </button>
      </div>

      <!-- 错误提示 -->
      <div *ngIf="form.get('level3Resources')?.touched && form.get('level3Resources')?.errors"
           class="text-red-500 text-sm mt-1">
        请至少添加一门课程
      </div>

      <!-- 课程列表 -->
      <div class="flex flex-col gap-2">
        <div formArrayName="level3Resources">
          <div *ngFor="let level3ResourceControl of getLevel3ResourcesForm().controls; let k = index"
               [formGroupName]="k"
               class="flex items-center gap-4 mt-4">
            <div class="flex-1 flex items-start gap-3">

              <!-- 课程类型 -->
              <nz-form-control *ngIf="form.get('type')?.value === ResourcePushWeekTemplateHomeworkTypeValues.BOOK"
                               class="!w-24 !flex-none"
                               [nzValidateStatus]="getValidateStatus(level3ResourceControl.get('level3ResourceType'))">
                <nz-select
                  formControlName="level3ResourceType"
                  nzPlaceHolder="请选择类型">
                  <nz-option *ngFor="let level3ResourceType of [ResourcePushWeekTemplateHomeworkLessonSubTypeItems.CARTOON, ResourcePushWeekTemplateHomeworkLessonSubTypeItems.RECORD_AUDIO]"
                             [nzValue]="level3ResourceType.value"
                             [nzLabel]="level3ResourceType.label"></nz-option>
                </nz-select>
              </nz-form-control>
              
              <!-- 课程选择 -->
              <nz-form-control [nzValidateStatus]="getValidateStatus(level3ResourceControl.get('level3ResourceId'))"
                               class="!w-72 !flex-none">
                <nz-select
                  formControlName="level3ResourceId"
                  nzPlaceHolder="请选择课程"
                  nzShowSearch
                >
                  <nz-option *ngFor="let level3Resource of getLevel3Resources(level3ResourceControl)"
                             [nzValue]="level3Resource.id"
                             [nzLabel]="level3Resource.name"></nz-option>
                </nz-select>
              </nz-form-control>

              <!-- 课程类型 -->
              <nz-form-control *ngIf="form.get('type')?.value === ResourcePushWeekTemplateHomeworkTypeValues.CARTOON"
                               class="!w-24 !flex-none"
                               [nzValidateStatus]="getValidateStatus(level3ResourceControl.get('level3ResourceType'))">
                <nz-select
                  formControlName="level3ResourceType"
                  nzPlaceHolder="请选择类型">
                  <nz-option *ngFor="let level3ResourceType of ResourcePushWeekTemplateHomeworkLessonSubTypes"
                             [nzValue]="level3ResourceType.value"
                             [nzLabel]="level3ResourceType.label"></nz-option>
                </nz-select>
              </nz-form-control>

              <!-- 课程内容 -->
              <nz-form-control [nzValidateStatus]="getValidateStatus(level3ResourceControl.get('level4ResourceIds'))"
                               class="flex-1">
                <nz-select
                  formControlName="level4ResourceIds"
                  nzMode="multiple"
                  nzShowSearch
                  nzPlaceHolder="请选择课程内容">
                  <nz-option *ngFor="let level4Resource of getLevel4Resources(level3ResourceControl)"
                             [nzValue]="level4Resource.id"
                             [nzLabel]="level4Resource.name"></nz-option>
                </nz-select>
              </nz-form-control>
            </div>

            <!-- 删除按钮 -->
            <div>
              <div
                class="flex items-center justify-center text-red-400 text-xl cursor-pointer"
                (click)="handleDeleteLevel3Resource(k)">
                <i nz-icon nzType="minus-circle" nzTheme="fill"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 作业操作按钮 -->
  <div class="flex items-center gap-4 text-xl px-2">
    <div (click)="handleDeleteHomework()"
         class="flex justify-center items-center text-red-400 cursor-pointer hover:scale-110 transition-transform">
      <i nz-icon nzType="minus-circle" nzTheme="fill"></i>
    </div>
    <div (click)="handleAddHomework()"
         class="flex justify-center items-center text-green-400 cursor-pointer hover:scale-110 transition-transform">
      <i nz-icon nzType="plus-circle" nzTheme="fill"></i>
    </div>
  </div>
</div>
