import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {ResourcePushWeek, ResourcePushTemplateDayOfWeek, ResourcePushWeekTemplateHomework, ResourcePushWeekTemplate} from 'src/app/model/ResourcePushTemplate';
import { AdminResourceCategoryTemplateHomeworkComponent } from './homework.component';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-admin-resource-category-template-week',
    templateUrl: './week.component.html',
})
export class AdminResourceCategoryTemplateWeekComponent implements OnInit {

    @Input() template!: ResourcePushWeekTemplate;
    @Output() change = new EventEmitter<ResourcePushWeekTemplate>();
    @Output() delete = new EventEmitter<void>();

    form!: FormGroup;
    dayOfWeekSelected: ResourcePushTemplateDayOfWeek = 1;
    week!: ResourcePushWeek;
    @ViewChildren(AdminResourceCategoryTemplateHomeworkComponent)
    homeworkComponents!: QueryList<AdminResourceCategoryTemplateHomeworkComponent>;

    protected readonly ResourcePushTemplateDayOfWeek = ResourcePushTemplateDayOfWeek;

    constructor(
        private fb: FormBuilder,
        private message: NzMessageService
    ) {

    }

    ngOnInit(): void {
        this.switchWeekOfDay(this.dayOfWeekSelected, true);
        this.form = this.fb.group({
            remark: this.fb.control(this.week.remark),
        });

        this.form.get('remark')?.valueChanges.subscribe((remark) => {
            this.week.remark = remark;
            this.emitChange();
        });
    }

    switchWeekOfDay(dayOfWeek: ResourcePushTemplateDayOfWeek, init = false) {

        if (dayOfWeek === this.dayOfWeekSelected && !init) {
            return;
        }

        if (!init && !this.validateHomework()) {
            this.message.error('请检查作业填写');
            return;
        }

        this.dayOfWeekSelected = dayOfWeek;
        const week = this.template.weeks.find(w => w.dayOfWeek === dayOfWeek);
        if (week) {
            this.week = week;
        } else {
            this.week = {
                remark: '',
                dayOfWeek: this.dayOfWeekSelected,
                homeworks: [],
            };
            this.template.weeks.push(this.week);
        }

        if (this.form) {
            this.form.get('remark')?.setValue(this.week.remark);
        }
    }

    isDayOfWeekSelected(dayOfWeek: ResourcePushTemplateDayOfWeek): boolean {
        return this.dayOfWeekSelected === dayOfWeek;
    }

    handleHomeworkChange(index: number, homework: ResourcePushWeekTemplateHomework) {
        this.week.homeworks[index] = Object.assign(this.week.homeworks[index], homework);
        this.emitChange();
    }

    handleDeleteHomework(index: number) {
        this.week.homeworks.splice(index, 1);
        this.emitChange();
    }
    
    handleAddHomework(index?: number) {
        if (index === undefined) {
            this.week.homeworks.push({});
        } else {
            this.week.homeworks.splice(index + 1, 0, {});
        }
        this.emitChange();
    }

    private emitChange() {
        this.change.emit(this.template);
    }

    validate() {
        this.form.markAllAsTouched();
        return this.form.valid && this.validateHomework();
    }

    validateHomework() {
        let isValid = true;
        for (const homeworkComponent of this.homeworkComponents) {
            if (!homeworkComponent.validate()) {
                isValid = false;
            }
        }
        return isValid;
    }


}
