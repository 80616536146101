import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../../services/school.service';
import { ClassService } from '../../../../../../services/class.service';
import { UserStatus, UserType } from '../../../../../../model/Const';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, UntypedFormControl } from '@angular/forms';
import { CoursewareService } from '../../../../../../services/courseware.service';
import { TwNoticeService } from '../../../../../../services/tw-notice.service';
import * as _ from 'lodash';
import { HWResourceService } from '../../../../../../services/huawei-resource.service';
import { SyllabusService } from '../../../../../../services/syllabus.service';
import { MediaV2Service } from '../../../../../../services/media-v2.service';

@Component({
  selector: 'app-admin-resource-media-form',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class AdminResourceMediaFormComponent implements OnInit {
  user: any = null;
  constUserType = UserType;
  currentSyllabusId = 0;
  currentCourseId = 0;

  isLoading = false;
  form!: UntypedFormGroup;

  syllabusList: { syllabus_id: number, course_id: number}[] = [];


  book_id = 0;
  bookAttrs: any = {};
  syllabusChildren = [];
  syllabusKeyValueItems: any = {};

  rootThemesKeyValueItems: any = {};
  subThemesKeyValueItems: any = {};



  bisType = 'cartoon';
  resourceType = '';
  tabIndex = 0;
  items_status: any = {};
  // readingStatus = false;
  // classroomReadingStatus = false;

  host = '';
  protocol = '';
  bucket = '';

  constructor(private userService: UserService,
              private clsService: ClassService,
              private mediaService: MediaV2Service,
              private syllabusService: SyllabusService,
              private activatedRoute: ActivatedRoute ,
              private twNoticeService: TwNoticeService ,
              private router: Router ,
              private fb: UntypedFormBuilder,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    const u = new URL(window.location.href);
    this.protocol = u.protocol;
    this.host = HWResourceService.cdnHost;
    this.activatedRoute.title.subscribe({
      next: (title) => {
      }
    });
    console.log('[form] this.activatedRoute', this.activatedRoute.pathFromRoot[2].data);
    this.resourceType = this.activatedRoute.pathFromRoot[2].snapshot.url[2].path;
    this.activatedRoute.queryParams.subscribe( (query: any) => {
      if (query.tabIndex && parseInt(query.tabIndex, 10) === query.tabIndex * 1) {
        this.tabIndex = parseInt(query.tabIndex, 10);
      }
    });
    // this.activatedRoute.queryParams.subscribe( (query: any) => {
    //   if (query.page && parseInt(query.page, 10) === query.page * 1) {
    //     this.currentPageIndex = parseInt(query.page, 10);
    //   }
    // });


    this.activatedRoute.params.subscribe( params => {
      console.log('params', params);
      this.book_id = params['item_id'] || 0;
      this.form = this.fb.group({
        id: [ this.book_id ],
        // syllabus_id: [ '', [ Validators.required ] ],
        // course_id: [ '', [ Validators.required ] ],
        // theme: [ [] , [Validators.required] ],
        // level: [ '', [Validators.required]  ],
        // themes: this.fb.array([]),
        name: [ '' , [ Validators.required ]],
        cover: [ '', [ Validators.required ] ],
        tagsGroupArray: this.fb.array([])
      });
    });
    // @ts-ignore
    window.aaa = this;
  }
  // get themes() {
  //   return this.form.get('themes') as FormArray;
  // }
  get tagsGroupArray () {
    return (this.form.get('tagsGroupArray') || []) as FormArray;
  }

  addTagsGroup({syllabus_id = 0, course_id = 0}: {syllabus_id?: number, course_id?: number}) {
    console.log('addTagsGroup');
    this.tagsGroupArray.push(this.fb.group({
      syllabus_id: this.fb.control(+syllabus_id, Validators.required),
      course_id: this.fb.control(+course_id, Validators.required),
    }));
  }
  removeTagsGroup(index: number): void {
    console.log('removeTagsGroup', index);
    this.tagsGroupArray.removeAt(index);
  }
  clearTagsField({prefix = '', parent = null}: {prefix?: string, parent?: FormGroup|null}) {
    if (!parent) {
      parent = this.form;
    }
    const keys = Object.keys(parent.controls);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].startsWith(`${prefix}.`)) {
        parent.removeControl(keys[i]);
      }
    }
  }
  addTagsField({id, value = null, prefix = '', parent = null}: {id: any, value?: any, prefix?: string,  parent?: FormGroup|null}): void {
    if (!parent) {
      parent = this.form;
    }
    const tagsControl = [];
    parent.addControl(
      `${prefix}.` + id,
      // new UntypedFormControl(null, Validators.required)
      new UntypedFormControl(value)
    );
  }

  ngOnInit() {
    this.isLoading = true;

    // this.coursewareService.bookAttrs(this.bisType).then((bookAttrs) => {
    this.syllabusService.category(this.bisType).then((bookAttrs) => {
      console.log('bookAttrs', bookAttrs);
      const _themesKeyValueItems: any = {};
      bookAttrs.themes.forEach((theme: any) => {
        if (!_themesKeyValueItems[theme.syllabus_id] ) {
          _themesKeyValueItems[theme.syllabus_id] = [];
        }
        _themesKeyValueItems[theme.syllabus_id].push(theme);
      });





      const _syllabusKeyValueItems: any = {};
      const _rootThemesKeyValueItems: any = {};

      bookAttrs.syllabus.forEach((syllabus: any) => {
        if (!_syllabusKeyValueItems[syllabus.id] ) {
          _syllabusKeyValueItems[syllabus.id] = [];
        }
        if (_themesKeyValueItems[syllabus.id]) {

          _rootThemesKeyValueItems[syllabus.id] = _themesKeyValueItems[syllabus.id];
          delete _themesKeyValueItems[syllabus.id];
        }
        _syllabusKeyValueItems[syllabus.id].push(syllabus);
      });
      this.rootThemesKeyValueItems = _rootThemesKeyValueItems;
      this.subThemesKeyValueItems = _themesKeyValueItems;
      this.syllabusKeyValueItems = _syllabusKeyValueItems;

      const _allThemesKeyValueItems: any = {};
      const aa = _.groupBy(bookAttrs.themes, 'syllabus_id');
      console.log('tag group ', aa);

      this.bookAttrs = bookAttrs;
      if (this.book_id) {
        this.mediaService.fetch(this.resourceType, this.book_id).then((result) => {
          console.log('mediaService.fetch', result);
          this.form.controls['name'].setValue(result.name);
          const cover = (result.cover && (result.cover.startsWith('http') ? result.cover : this.protocol + '//' + this.host + '/' + (this.bucket ? this.bucket + '/' : '') + result.cover));
          this.form.controls['cover'].setValue(cover);
          this.items_status = result.items_status;

          result.themes.forEach((tagGroup: any) => {
            this.addTagsGroup(tagGroup);
            // syllabusChildren
            const groupArray = this.form.controls['tagsGroupArray'] as FormArray;
            const group = groupArray.at(groupArray.length - 1) as FormGroup;
            // if (this.rootThemesKeyValueItems[tagGroup.syllabus_id]) {
            //   this.rootThemesKeyValueItems[tagGroup.syllabus_id].forEach((theme: any) => {
            //     this.addTagsField({id: theme.id , value: tagGroup.theme_id, prefix: 'theme_group_id', parent: group});
            //   });
            // }
            tagGroup.themes.forEach((v: any) => {
              this.addTagsField({id: v.theme_group_id , value: v.theme_id, prefix: 'theme_group_id', parent: group});

              // if (this.rootThemesKeyValueItems[v.syllabus_id]) {
              //   this.rootThemesKeyValueItems[v.syllabus_id].forEach((theme: any) => {
              //     this.addTagsField({id: theme.id , value: v.theme_id, prefix: 'theme_group_id', parent: group});
              //   });
              // }
              // if (this.subThemesKeyValueItems[v.course_id]) {
              //   this.subThemesKeyValueItems[v.course_id].forEach((theme: any) => {
              //     this.addTagsField({id: theme.id, value: v.theme_id, prefix: 'theme_id', parent: group});
              //   });
              // }
            });
          });




          this.isLoading = false;
        }).catch(err => {
            throw err;
        });
      } else {
        this.isLoading = false;
      }

    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }

  currentSubOptions (group_index: number) {
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    const syllabus_id = group.controls['syllabus_id'];
    const item = this.bookAttrs.syllabus.find((s: any) => {
      return +s.id === +syllabus_id.value;
    });
    return  item?.children || [];
  }

  currentRootTags() {

  }

  changeSyllabus(group_index: number, syllabus_id: number) {
    console.log('changeSyllabus', group_index, syllabus_id);
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    group.controls['course_id'].setValue('');
    this.clearTagsField({prefix: 'theme_group_id', parent: group});
    // this.form.controls['course_id'].setValue('');
    if (!syllabus_id) {
      this.currentSyllabusId = 0;
      this.currentCourseId = 0;
      this.syllabusChildren = [];
      return;
    }
    const item = this.bookAttrs.syllabus.find((s: any) => {
      return +s.id === +syllabus_id;
    });
    this.syllabusChildren = item.children;
    this.currentSyllabusId = syllabus_id;
    const themes = this.rootThemesKeyValueItems[syllabus_id] || [];
    // this.rootSyllabusThemes = this.syllabusKeyValueItems[syllabus_id] || [];

    themes.forEach((v: any) => {
      this.addTagsField({id: v.id, prefix: 'theme_group_id', parent: group});
    });
  }

  changeCourse(group_index: number, course_id: number) {
    console.log('changeCourse', group_index, course_id);
    const group = (this.tagsGroupArray.controls[group_index] as FormGroup);
    this.clearTagsField( {prefix: 'theme_id', parent: group});
    if (!course_id) {
      this.currentCourseId = 0;
      return;
    }

    const item = this.bookAttrs.themes.filter((s: any) => {
      return +s.syllabus_id === +course_id;
    });
    this.currentCourseId = course_id;
    const themes = this.subThemesKeyValueItems[course_id] || [];

    themes.forEach((v: any) => {
      this.addTagsField({id: v.id, prefix: 'theme_id', parent: group});
    });
  }

  tabIndexChange($evt: any) {
    console.log('tabIndexChange', $evt, this.tabIndex);
    this.tabIndex = $evt;
  }
  noticeReadingStatus() {
    this.twNoticeService.warning({nzContent: '請先上傳Reading素材'});
  }
  setCover(event: any) {
    this.form.controls['cover'].setValue(event.url);
  }

  submitForm = ($event: any, value: any) => {
    if ($event) {
      $event.preventDefault();
    }
    if (this.isLoading) {
      return;
    }
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
    }
    console.log('submitForm', $event, value);
    this.isLoading = true;
    this.mediaService.save(this.resourceType, value).then((result) => {
      this.isLoading = false;
      const modal = this.twNoticeService.success({nzContent: '保存成功'});
      modal.afterClose.subscribe(() => {
        if (!this.book_id) {
          // this.router.navigate([`/admin/v3/resource/${this.bisType}/audio`]);
        }

      });
    }).catch(err => {
      this.isLoading = false;
      this.nzMessageService.error(err.message || 'System Error');
    });
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsPristine();
      this.form.controls[ key ].updateValueAndValidity();
    }
  }

  dragStart($evt: any) {
    console.log('dragStart', $evt);
  }
  dragging($evt: any) {
    console.log('dragging', $evt);
  }

  protected readonly UserStatus = UserStatus;
  protected readonly Boolean = Boolean;
}
