import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import {BaseService} from "./base.service";
import { Injectable } from "@angular/core";
import { ResourcePushWeekTemplate } from "../model/ResourcePushTemplate";

@Injectable({
    providedIn: 'root'
})
export class HomeworkPushTemplatesService extends BaseService {

    constructor(protected override http: HttpClient, protected override router: Router) {
        super(http, router);
    }

    async list(categoryId: number, subCategoryId: number, ageLevel: number): Promise<ResourcePushWeekTemplate[]> {
        return await super.get(`/homework-push-templates?categoryId=${categoryId}&subCategoryId=${subCategoryId}&ageLevel=${ageLevel}`);
    }

    async update(categoryId: number, subCategoryId: number, ageLevel: number, templates: ResourcePushWeekTemplate[]) {
        return await super.put(`/homework-push-templates?categoryId=${categoryId}&subCategoryId=${subCategoryId}&ageLevel=${ageLevel}`, templates);
    }

    async delete(id: number) {
        await super.remove(`/homework-push-templates/${id}`);
    }
    
    
}