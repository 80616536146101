<div *ngIf="loading" class="flex justify-center py-10">
  <nz-spin nzSimple nzSize="large"></nz-spin>
</div>
<div class="bg-white rounded-md overflow-clip pb-4" *ngIf="!loading">
  <div class="px-5 py-4 font-semibold">
    {{ ageLevelLabel }}
  </div>
  <nz-divider class="m-0"/>


  <div class="flex flex-col gap-4">
    <app-admin-resource-category-template-week
      *ngFor="let template of templates; let i = index"
      [template]="template"
      (change)="handleTemplateChange(i, template)"
      (delete)="handleDeleteTemplate(i)"
    ></app-admin-resource-category-template-week>
  </div>

  <div class="px-4 flex flex-col gap-4 mt-4">

    <div>
      <button nz-button nzType="dashed" (click)="handleAddTemplate()" nzSize="large" nzBlock="true">
        添加周
      </button>
    </div>

    <div>
      <button nz-button nzType="primary" (click)="handleSubmit()" nzSize="large" class="px-10" [nzLoading]="saving">
        保存
      </button>
    </div>
  </div>

</div>
