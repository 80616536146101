import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
// @ts-ignore
// import OSS from 'ali-oss';
// @ts-ignore
import ObsClient from 'esdk-obs-browserjs';
// @ts-ignore
import SparkMD5 from 'spark-md5';
import { UserService } from './user.service';
import { ResType } from '../model/Const';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HWResourceService extends BaseService {

  // @ts-ignore
  constructor(protected http: HttpClient,  private userService: UserService, protected router: Router) {
    super(http, router);
    // this.domain = `${this.schema}://${this.bucket}.${this.region}.aliyuncs.com`;
  }

  static bucketPath = 'ireadabc';
  static cdnHost = 'hw-cdn.ireadabc.com';
  bucket = 'ireadabc';
  ak = '0MIC80VIWUFAV92NU1FA';
  sk = '17xeJueRtxwXRBmd3qq3X6SUOz1Bip9BGEytWR4L';
  cdn = 'hw-cdn.ireadabc.com';
  source = 'ireadabc.obs.cn-north-4.myhuaweicloud.com';
  server = 'obs.cn-north-4.myhuaweicloud.com';
  schema = 'https';

  checkFileHash (file?: File & {url: string}): Promise<string>|null {
    if (!file) {
      return null;
    }
    const self = this;
    const md5Lib = SparkMD5;
    return new Promise((resolve, reject) => {
      // eval(self.workerThreadSource);

      const blobSlice = File.prototype.slice,
        chunkSize = 2097152, // Read in chunks of 2MB
        chunks = Math.ceil(file.size / chunkSize),
        spark = new SparkMD5.ArrayBuffer(),
        fileReader = new FileReader();

      let currentChunk = 0;

      fileReader.onload = function (evt: any) {
        // console.log('read chunk nr', currentChunk + 1, 'of', chunks);
        spark.append(evt.target.result); // Append array buffer
        // spark.append(this.result); // Append array buffer
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          // Compute hash
          const file_hash = spark.end();
          resolve(file_hash) ;
        }
      };

      fileReader.onerror = function () {
        console.warn('oops, something went wrong.');
        reject();
      };

      function loadNext() {
        try {
          if (!file) {
            return;
          }
          const start = currentChunk * chunkSize,
            end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        } catch (e) {
          reject(e);
        }

      }
      loadNext();
    });

  }

  checkOSSObject(hash: string, bucket?: string) {

    const url =  `${this.schema}://${this.source}`  + (hash.startsWith('/') ? hash : `/${hash}`);
      // `https://${this.domain}` + hash;
    console.log('check', url);
    // @ts-ignore
    // return OSS.urllib.request(url, {type: 'head'}).then(info => {
    return fetch(url, {method: 'HEAD'}).then((info: any) => {
      console.log('check ok', info);
      const {status} = info;
      if (status === 200 || status === 304) {
        info.url = url;
        return {info, client: null};
      } else {
        return this.getObsClient().then(client => {
          return {info: null, client};
        });

      }
    }).catch((err: any) => {
      return this.getObsClient().then(client => {
        return {info: null, client};
      });
    });
  }

  async getObsClient() {

    const client =  new ObsClient({
      access_key_id: this.ak,
      secret_access_key: this.sk,
      server : this.server,
      timeout : 60 * 30,
      // signature : 'obs'
    });
    return client;
  }


  doUpload = (options: {file: File & {url: string}|null, /* type: ResType,*/
              osspath: string,
              hash?: string,
              bucket?: string,
              progress_cb?: (s: any) => any,
              success_cb?: (name: any, hash: any, url: string, file?: File, inOSS?: boolean) => any,
              error_cb?: (s: any, file?: File) => any,
              check_cb?: (s: any) => any }) => {
    // const osspath = '/images/'
    // comp.updateStatus(false);
    let hasher = null;
    if (options.hash) {
      // @ts-ignore
      hasher = new Promise<string>((resolve, reject) => resolve(options.hash));
    } else {
      // @ts-ignore
      hasher = this.checkFileHash(options.file);
    }
    if (!hasher) {
      throw new Error('canot dected checker');
    }

    hasher.then((hash: string) => {
      if (options.check_cb) {
        options.check_cb(hash);
      }
      const ext = options?.file?.name.substr(options?.file?.name.lastIndexOf('.') );
      console.log('osspath', options.osspath + hash + ext);
      let checker = null;
      if (options.hash) {
        checker = this.getObsClient().then(client => {
          return {info: null, client};
        });
      } else {
        checker = this.checkOSSObject(options.osspath + hash + ext, options.bucket);
      }
      checker.then((res: any) => {
        const {info, client} = res;
        if (!client && !info) {
          // @ts-ignore
          options?.error_cb({message: 'system error'});
          return ;
        }
        if (info) {
          let url = info.url || info.res.requestUrls[0];

          const u = new URL(url);
          // if (u.host !== this.domain) {
            u.protocol = `${this.schema}:`;
            // u.host = this.domain;
            u.host = `${this.cdn}`;
            url = u.toString();
          // }

          console.log(url);
          if ( url.indexOf('?') > -1 ) {
            url = url.substring(0, url.indexOf('?'));
          }
          // @ts-ignore
          options?.success_cb(options?.file?.name, hash, url, options?.file, true);

        } else if (client) {
          this.uploadFile(client,
            options.file, options.osspath,
            hash,  /*type,*/ options.progress_cb,
            options.success_cb, options.error_cb);
        }
      }).catch((e: any) => {
        // @ts-ignore
        options?.error_cb({message: '上传失败，请重试'});
      });
    });

  }
  async uploadFile (client: any, file: any, path: string,  hash: string,  /*type,*/  progress_cb: any, success_cb: any, error_cb: any) {
    // console.log('uploadFile', hash);
    const ext = file.name.substr(file.name.lastIndexOf('.') );

    let key = path + hash + ext; // `${this.item.u_id}_${hash}_${file.name}`;
    key = key.startsWith('/') ? key.substr(1) : key;
    // console.log(file.name + ' => ' + key);
    // const self = this;
    const user_info = this.userService.getUserInfo();

    const meta = {
      fileName: encodeURI(file.name),
      fileMd5: hash
    };
    if (user_info && user_info.id) {
      // @ts-ignore
      meta.userId = user_info.id;
    }

    client.putObject({
      Bucket : this.bucket,
      Key : key,
      SourceFile : file,
      ProgressCallback: progress_cb,
      Metadata: meta
    }).then((resp: any) => {
      if (resp.CommonMsg.Status  < 300) {

        success_cb(file.name, hash, `${this.schema}://${[this.cdn, key ].join('/')}`, file);
      } else {
        error_cb({message: resp.CommonMsg.Message}, file);
      }
      return;
      let url = resp.res.requestUrls[0];
      const u = new URL(url);
      // if (u.host !== this.domain) {
        u.protocol = `${this.schema}:`;
        u.host = `${this.cdn}`;
        url = u.toString();
      // }
      console.log('asdfasfasd', url);
      if ( url.indexOf('?') > -1 ) {
        url = url.substring(0, url.indexOf('?'));
      }
      success_cb(file.name, hash, url, file);

    }).catch((e: any) => {
      if (error_cb) {
        error_cb(e, file);
      }
    });
  }

  add(data: any) {
    return super.post(`/resource`, data).then((resp) => {
      return resp;
    });
  }
  getByHash(type: ResType, hash: string) {
    // let t = '0';
    // if (type === ResType.PIC) {
    //   t = 'image';
    // } else if (type === ResType.AUDIO) {
    //   t = 'audio';
    // }
    return super.get(`/resource/info/${type}/${hash}`);
  }
  updateAudio(id: string, data: string) {
    return super.post(`/resource/audio/${id}`, data).then((resp) => {
      return resp;
    });
  }
  updateImage(id: string, data: string) {
    return super.post(`/resource/image/${id}`, data).then((resp) => {
      return resp;
    });
  }
  updateVideo(id: string, data: string) {
    return super.post(`/resource/video/${id}`, data).then((resp) => {
      return resp;
    });
  }
}
