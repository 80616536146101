

export const ResourcePushTemplateAgeLevels = [
    { value: 1, label: '三周岁起点' },
    { value: 2, label: '四周岁起点' },
    { value: 3, label: '五周岁起点' },
    { value: 4, label: '一二年级起点' },
    { value: 5, label: '三四年级起点' },
] as const;

export const ResourcePushTemplateAgeLevelsMap = ResourcePushTemplateAgeLevels.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
}, {} as Record<ResourcePushTemplateAgeLevel, string>);

export type ResourcePushTemplateAgeLevel = (typeof ResourcePushTemplateAgeLevels)[number]['value'];

export const ResourcePushTemplateDayOfWeek = [
    { value: 1, label: '周一' },
    { value: 2, label: '周二' },
    { value: 3, label: '周三' },
    { value: 4, label: '周四' },
    { value: 5, label: '周五' },
    { value: 6, label: '周六' },
    { value: 7, label: '周日' },
    { value: 0, label: '整周' },
] as const;


export type ResourcePushTemplateDayOfWeek = (typeof ResourcePushTemplateDayOfWeek)[number]['value'];
export const ResourcePushTemplateDayOfWeekMap = ResourcePushTemplateDayOfWeek.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
}, {} as Record<ResourcePushTemplateDayOfWeek, string>);

export interface ResourcePushWeekTemplate {
    seq: number;
    weeks: ResourcePushWeek[];
}

export interface ResourcePushWeek {
    remark: string;
    dayOfWeek: ResourcePushTemplateDayOfWeek;
    homeworks: ResourcePushWeekTemplateHomework[];
}

export const ResourcePushWeekTemplateHomeworkTypes = [
    { value: 'cartoon', label: '绘本', key: 'CARTOON' },
    { value: 'exercise', label: '习题', key: 'EXERCISE' },
    { value: 'video', label: '视频', key: 'VIDEO' },
    { value: 'book', label: '教材', key: 'BOOK' },
    { value: 'audio', label: '音频', key: 'AUDIO' },
    // { value: 'dubbing', label: '随心配', key: 'DUBBING' },
] as const;

export const ResourcePushWeekTemplateHomeworkTypeValues = ResourcePushWeekTemplateHomeworkTypes.reduce((acc, curr) => {
    acc[curr.key] = curr.value;
    return acc;
}, {} as Record<typeof ResourcePushWeekTemplateHomeworkTypes[number]['key'], typeof ResourcePushWeekTemplateHomeworkTypes[number]['value']>);

export type ResourcePushWeekTemplateHomeworkType = (typeof ResourcePushWeekTemplateHomeworkTypes)[number]['value'];

export const ResourcePushWeekTemplateHomeworkTypesMap = ResourcePushWeekTemplateHomeworkTypes.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
}, {} as Record<ResourcePushWeekTemplateHomeworkType, string>);

export interface ResourcePushWeekTemplateHomework {
    type?: ResourcePushWeekTemplateHomeworkType | null;
    level1ResourceId?: number | null;
    level1ResourceName?: string | null;
    level2ResourceId?: number | null;
    level2ResourceName?: string | null;
    level3ResourceIds?: number[];
    level3Resources?: ResourcePushWeekTemplateLevel3Resource[];
}

export interface ResourcePushWeekTemplateLevel3Resource {
    level3ResourceId?: number | null;
    level3ResourceType?: number | null;
    level4ResourceIds?: number[];
}


export const ResourcePushWeekTemplateHomeworkLessonSubTypes = [
    { value: 1, label: '阅读', key: 'CARTOON' },
    { value: 2, label: '视频', key: 'VIDEO' },
    { value: 3, label: '音频', key: 'AUDIO' },
    { value: 4, label: '习题', key: 'EXERCISE' },
    { value: 5, label: '录音', key: 'RECORD_AUDIO' },
] as const;

export const ResourcePushWeekTemplateHomeworkLessonSubTypeValues = ResourcePushWeekTemplateHomeworkLessonSubTypes.reduce((acc, curr) => {
    acc[curr.key] = curr.value;
    return acc;
}, {} as Record<typeof ResourcePushWeekTemplateHomeworkLessonSubTypes[number]['key'], typeof ResourcePushWeekTemplateHomeworkLessonSubTypes[number]['value']>);


export const ResourcePushWeekTemplateHomeworkLessonSubTypeItems = ResourcePushWeekTemplateHomeworkLessonSubTypes.reduce((acc, curr) => {
    acc[curr.key] = curr;
    return acc;
}, {} as Record<typeof ResourcePushWeekTemplateHomeworkLessonSubTypes[number]['key'], typeof ResourcePushWeekTemplateHomeworkLessonSubTypes[number]>);

export type ResourcePushWeekTemplateHomeworkLessonSubType = (typeof ResourcePushWeekTemplateHomeworkLessonSubTypes)[number]['value'];

export const ResourcePushWeekTemplateHomeworkLessonSubTypesMap = ResourcePushWeekTemplateHomeworkLessonSubTypes.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
}, {} as Record<ResourcePushWeekTemplateHomeworkLessonSubType, string>);

export interface ResourcePushWeekTemplateHomeworkResource {
    id: number;
    name: string;
}
