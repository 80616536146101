import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { ExerciseService } from '../../../../../services/exercise.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Validators } from '@angular/forms';




@Component({
  selector: 'book-exercise-tab',
  template: `
    <div class="page-main">
      <div class="page-main-header">
        <div class="page-main-header-tools">
          <button nz-button nzType="primary" nzGhost (click)="showCreateExerciseDialog()">
            <i nz-icon nzType="plus" nzTheme="outline" class="fix-icon" ></i>
            添加习题
          </button>
        </div>
        <div class="page-main-header-actions">
        </div>
      </div>
      <div class="page-main-wrapper" style="padding: 0 2rem;">
        <ul nz-list [nzDataSource]="exercise_items" nzBordered nzSize="large" >
          <nz-list-header>习题列表</nz-list-header>
          <li nz-list-item *ngFor="let item of exercise_items" nzNoFlex>
            <ul nz-list-item-actions>
              <nz-list-item-action>
                <a (click)="deleteExercise(item)">delete</a>
                <nz-divider nzType="vertical"></nz-divider>
                <a (click)="editExercise(item)">edit</a>
              </nz-list-item-action>
            </ul>
            <span style="color: #666">{{ item.content.question }}</span>
          </li>
<!--          <nz-list-footer>Footer</nz-list-footer>-->
        </ul>
        
      </div>
    </div>


    <ng-template #modalContent>
      
      <nz-radio-group style="width: 100%" [(ngModel)]="questionItem.content.answer">
        <div class="flex">
          <div class="lbl">题干</div>
          <div class="flex-1">
            <input nz-input [(ngModel)]="questionItem.content.question" placeholder="题干">
          </div>
        </div>
        <div class="flex flex-1 flex-row" *ngFor="let option of questionItem.content.options; trackBy:indexTracker; let i  = index;">
          <div class="flex-0 lbl" style="width: 100px">选项{{this.alphabetIndexArray[i]}}</div>
          <nz-input-group class="flex-1" [nzAddOnAfter]="addOnAfterTemplate">
            <input nz-input
                   [(ngModel)]="questionItem.content.options[i]"  />
            <ng-template #addOnAfterTemplate>
              <label nz-radio [nzValue]="i">正确选项</label>
            </ng-template>
          </nz-input-group>
          <div class="flex-0 lbl" style="width: 30px" *ngIf="i > 1">
            <span nz-icon nzType="minus-circle" style="font-size: 20px; color: #08c;line-height: 31px;margin-left:4px;cursor: pointer;" nzTheme="outline" 
                  (click)="deleteExerciseOption(i)"></span></div>
        </div>
      </nz-radio-group>
      <button nz-button [nzType]="'primary'" [nzSize]="'small'" nzGhost (click)="addOption()">增加选项
      </button> 
      
      <div>
        <div class="lbl">解析</div>
        <nz-input-group [nzSuffix]="textAreaClearTpl" class="ant-input-affix-wrapper-textarea-with-clear-btn">
          <textarea nz-input
                    rows="3"      
                    [(ngModel)]="questionItem.content.analysis"></textarea>
        </nz-input-group>
        <ng-template #textAreaClearTpl>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="questionItem.content.analysis"
            (click)="questionItem.content.analysis = ''"
          ></span>
        </ng-template>
      </div>
      <div class="border-t pt-4 mt-4 text-right">
        <button class="mr-4" nz-button [nzType]="'primary'" [nzSize]="'default'" (click)="saveExercise()">
          确定
        </button>
        <button nz-button [nzType]="'primary'" [nzSize]="'default'" nzGhost (click)="closeCreateExerciseDialog()">关闭
        </button>
      </div>
    </ng-template>
    
    <nz-modal
      [nzWidth]="'650px'"
      nzWrapClassName="v3"
      [nzKeyboard]="false"
      [nzMaskClosable]="false"
      [nzClosable]="false"
      [(nzVisible)]="createExerciseDialog"
      [nzContent]="modalContent"
      [nzTitle]="null"
      [nzFooter]="null"
      (nzOnCancel)="closeCreateExerciseDialog()"
    >

     

    </nz-modal>

  `,
  styles: [
    `
      .lbl{
        width: 100px;
        color: #666;
        line-height: 31px;
        margin-bottom: 8px;
        font-size: 14px;
      }
    `
  ]
})
export class BookExerciseTabComponent implements OnInit {
  createExerciseDialog = false;
  loading = false;

  @Input() exercise_items: any[] = [];
  @Input() school_id = 0;

  @Output() refresh = new EventEmitter();

  questionItemTpl: any = {
    id: 0,
    content: {
      answer: [],
      options: [],
      analysis: '',
      question: ''
    }
  };
  questionItem: any = {};
  item_id = 0;

  alphabetIndexArray = [];

  constructor(
    private exerciseService: ExerciseService,
    private nzMessageService: NzMessageService,
    private activatedRoute: ActivatedRoute ,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      console.log('params', params);
      this.item_id = +params['item_id'] || 0;
    });
    for (let i = 65; i <= 90; i++) {
      // @ts-ignore
      this.alphabetIndexArray.push(String.fromCharCode(i));
    }
    // @ts-ignore
    window.bbb = this;
  }
  indexTracker(index: number, value: any) {
    return index;
  }
  showCreateExerciseDialog() {
    this.questionItem = JSON.parse(JSON.stringify(this.questionItemTpl));
    this.questionItem.content.options.push('');
    this.questionItem.content.options.push('');
    this.createExerciseDialog = true;
  }
  closeCreateExerciseDialog() {
    this.createExerciseDialog = false;
  }

  addOption() {
    this.questionItem.content.options.push('');
  }

  saveExercise() {
    const data = JSON.parse(JSON.stringify(this.questionItem));
    if (this.questionItem.content.answer.length ===  0) {
      this.nzMessageService.error('请配置正确选项');
      return;
    }
    data.content.answer = [this.questionItem.content.answer];
    data.exercise_id = this.item_id;
    data.school_id = this.school_id;

    console.log('saveExercise', data);
    this.exerciseService.saveQuestion('exercise', data).then(r => {
      console.log('saveExercise', r);
      this.closeCreateExerciseDialog();
      this.refresh.emit();
    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
    });
  }
  deleteExerciseOption(idx: number) {
    this.questionItem.content.options.splice(idx, 1);
  }

  deleteExercise(item: any) {
    this.exerciseService.deleteQuestion('exercise', item.id).then(r => {
      this.closeCreateExerciseDialog();
      this.refresh.emit();
    }).catch(err => {
      this.nzMessageService.error(err.message);
    });
  }
  editExercise(item: any) {
    const data = JSON.parse(JSON.stringify(item));
    data.content.answer = data.content.answer[0];
    this.questionItem = data;
    this.createExerciseDialog = true;
  }

  protected readonly JSON = JSON;
}
