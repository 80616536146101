import {Component, OnInit, ViewChildren, QueryList} from '@angular/core';
import {
    ResourcePushTemplateAgeLevel,
    ResourcePushTemplateAgeLevels,
    ResourcePushWeekTemplate,
} from '../../../../../../model/ResourcePushTemplate';
import {ActivatedRoute} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HomeworkPushTemplatesService } from 'src/app/services/homework-push-templates.service';
import {AdminResourceCategoryTemplateWeekComponent} from './week.component';

@Component({
    selector: 'app-admin-book-category-template',
    templateUrl: './index.component.html',
})
export class AdminBookCategoryTemplateComponent implements OnInit {

    ageLevel!: ResourcePushTemplateAgeLevel;
    categoryId!: number;
    categorySubId!: number;

    @ViewChildren(AdminResourceCategoryTemplateWeekComponent)
    templateComponents!: QueryList<AdminResourceCategoryTemplateWeekComponent>;
    templates: ResourcePushWeekTemplate[] = [];
    loading = true;
    saving = false;

    protected readonly ResourcePushTemplateAgeLevels = ResourcePushTemplateAgeLevels;

    constructor(
        private route: ActivatedRoute,
        private message: NzMessageService,
        private homeworkPushTemplatesService: HomeworkPushTemplatesService,
    ) {
        // @ts-ignore
        window.aaa = this;
    }

    get ageLevelLabel() {
        const item = ResourcePushTemplateAgeLevels.find((a: any ) => {
            return a.value === this.ageLevel;
        });
        return item?.label;
    }

    async ngOnInit() {
        this.ageLevel = Number(this.route.snapshot.queryParams['ageLevel']) as ResourcePushTemplateAgeLevel;
        this.categoryId = Number(this.route.snapshot.queryParams['categoryId']);
        this.categorySubId = Number(this.route.snapshot.queryParams['categorySubId']);
        await this.fetchData();
    }

    private async fetchData() {
        this.loading = true;
        this.templates = await this.homeworkPushTemplatesService.list(this.categoryId, this.categorySubId, this.ageLevel);
        this.loading = false;
    }

    handleTemplateChange(index: number, template: ResourcePushWeekTemplate) {
        this.templates[index] = Object.assign(this.templates[index], template);
    }

    handleAddTemplate() {
        const newTemplate: ResourcePushWeekTemplate = {
            seq: this.templates.length + 1,
            weeks: [],
        };
        this.templates.push(newTemplate);
    }

    handleDeleteTemplate(index: number) {
        this.templates.splice(index, 1)
        this.templates.forEach((template, index) => {
            template.seq = index + 1;
        });
    }


    validateForm(): boolean {
        let isValid = true;

        for (const templateComponent of this.templateComponents) {
            if (!templateComponent.validate()) {
                isValid = false;
            }
        }

        return isValid;
    }

    async handleSubmit() {
        if (this.saving) {
            return;
        }
        if (!this.validateForm()) {
            this.message.error('请检查表单填写是否正确');
            return;
        }

        this.saving = true;
        try {
            await this.homeworkPushTemplatesService.update(this.categoryId, this.categorySubId, this.ageLevel, this.templates);
            this.message.success('保存成功');
        } catch (error) {
            this.message.error('保存失败');
        } finally {
            this.saving = false;
        }
    }
}
