
<form [formGroup]="form">
    <div class="flex flex-col gap-4">
        <!-- title -->
        <div class="flex items-center px-4 py-1 bg-blue-50">
            <div class="flex-1">
            <span class="font-semibold">第{{ template.seq }}周</span>
            </div>
            <div>
            <button nz-button nzType="link" nzShape="circle" (click)="delete.emit()"
                    class="flex justify-center items-center">
                <i nz-icon nzType="delete" class="text-red-400"></i>
            </button>
            </div>
        </div>
    
        <!-- content -->
        <div class="flex flex-col px-4">
    
            <!-- daysOfTemplates -->
            <div class="flex gap-4 mb-4">
                <button
                    *ngFor="let day of ResourcePushTemplateDayOfWeek"
                    nz-button
                    class="px-6"
                    [nzType]="isDayOfWeekSelected(day.value) ? 'primary' : 'default'"
                    (click)="switchWeekOfDay(day.value)">
                    {{ day.label }}
                </button>
            </div>
    
            <!-- remark -->
            <nz-form-control>
                <textarea
                    nz-input
                    nzSize="large"
                    class="text-xs"
                    placeholder="备注"
                    rows="3"
                    formControlName="remark"></textarea>
            </nz-form-control>
    
            <div class="flex flex-col gap-4">
                <div *ngFor="let homework of week.homeworks; let i = index">
                    <app-admin-resource-category-template-homework
                    [index]="i"
                    [homework]="homework"
                    (homeworkChange)="handleHomeworkChange(i, $event)"
                    (deleteHomework)="handleDeleteHomework(i)"
                    (addHomework)="handleAddHomework(i)"
                    ></app-admin-resource-category-template-homework>
                </div>
            </div>
            <div *ngIf="week.homeworks.length === 0" class="pt-2 pb-1">
                <button nz-button nzType="dashed" (click)="handleAddHomework()">添加作业</button>
            </div>
        </div>
    
    </div>
    
</form>
