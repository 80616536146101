import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SystemService } from '../../../../../../services/system.service';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { UserService } from '../../../../../../services/user.service';
import { SchoolService } from '../../../../../../services/school.service';
import { HttpClient } from '@angular/common/http';
import { HWResourceService as ResourceService } from '../../../../../../services/huawei-resource.service';
import { DragElement } from '../../../../../../directives/drag-element';
import { VideoDelegate } from '../../../../../../directives/video-delegate';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { QupeiyinService } from '../../../../../../services/qupeiyin.service';


function arrayBufferToString( buffer: Buffer, encoding: string ) {
  return new Promise((resolve, reject) => {
    const blob = new Blob([buffer], { type: 'text/plain' });
    const reader = new FileReader();
    reader.onload = (evt) => {
      // @ts-ignore
      resolve(evt.target.result);
    };
    reader.readAsText(blob, encoding);
  });

}



@Component({
  selector: 'app-qupeiyin-form',
  templateUrl: './qupeiyin-form.component.html',
  styleUrls: ['./qupeiyin-form.component.scss']
})
export class QupeiyinFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  hasChanges = true;
  videoDelegate: any = null;
  thumbNumbers = 10;
  // LRCData: string;
  DUBData = {
    id: 0,
    title: '',
    cover_url: '',
    video_url: '',
    size: 0,
    width: 0,
    height: 0,
    duration: 0,
    timePointData: []
  };
  canSetTimeBlock = true;
  eventUpload = new EventEmitter();
  eventExtraCover = new EventEmitter();
  eventSelectFile = new EventEmitter();
  // @ts-ignore
  validateForm: UntypedFormGroup;
  isLoadingFormMeta = true;
  isFormMetaDataLoaded = false;

  formLevelOptions: any[] = [];
  formKindOptions: any[] = [];
  formThemeOptions: any[] = [];
  editId = 0;
  editData: any = null;
  // @ts-ignore
  @ViewChild('videoElement', {static: false }) videoElement: ElementRef;
  // @ts-ignore
  private dimensions: { width: number; height: number };
  private isSettingTimeBlockPoint: 'A'|'B'|null = null;
  private _tmpPosition = 0;
  private user_info: any;

  constructor(private sysSrv: SystemService,
              private userSrv: UserService,
              private schoolSrv: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private client: HttpClient,
              private sanitization: DomSanitizer,
              private modalService: NzModalService,
              private fb: UntypedFormBuilder,
              private qupeiyinSvc: QupeiyinService,
              private resourceSvc: ResourceService,
              private nzMessageService: NzMessageService) {
    this.user_info = this.userSrv.getUserInfo();
    // @ts-ignore
    window.q = this;
    this.activatedRoute.queryParams.subscribe( params => {
      if (params['id'] && parseInt(params['id'], 10) === params['id'] * 1) {
        this.editId = params['id'];
        this._currentStep = 1;
      }
    });
  }

  // @ts-ignore
  @ViewChild('canvasEl', {static: false }) canvasElRef: ElementRef;
  // @ts-ignore
  @ViewChild('rulerCanvasEl', {static: false }) rulerCanvasEl: ElementRef;
  // @ts-ignore
  @ViewChild('timeLineEl', {static: false }) timeLineEl: ElementRef;


  lrcFileEncoding = 'GB18030';
  timeRangeModeConfObj = {};
  timeRangeObj = {
    min: 0,
    max: 100
  };
  isScaleTimeLine = false;

  timeRangeSelector = [0, 100];
  currentAudioTime = '00:00:00.000';
  currentAudioDuration = '00:00:00.000';
  selectHighlightTimePointIndex = -1;
  _currentStep = 0;
  // item = {
  //   audio_url: null,
  //   mode: MODE.TEXT,
  //   lyrics: []
  // };
  isLoadingVideoData = false;
  // @ts-ignore
  waveWidth: number;
  // @ts-ignore
  waveHeight: number;
  // @ts-ignore
  ctx: CanvasRenderingContext2D;
  adContext = new AudioContext();
  // @ts-ignore
  timeLineTimer: number;
  timeLine: any;
  // timePointData = [];
  httpErrorTryCount = 0;
  httpErrorTryMax = 3;
  playbackRate = 1;
  // private audioBuffer: AudioBuffer;
  // @ts-ignore
  private audioArrayBuffer: ArrayBuffer;
  timePointDataFull: any;
  // @ts-ignore
  private currentTimeRangDuration: number;
  // @ts-ignore
  private timeRangeEditLines: number[];
  // @ts-ignore
  private videoPlayBarPositionSub: Subscription;
  // @ts-ignore
  private videoDataLoadedSub: Subscription;
  // @ts-ignore
  private videoPlayTimestampSub: Subscription;
  // @ts-ignore
  private videoPlayEndSub: Subscription;
  // @ts-ignore
  private dragMoveBarSub: Subscription;
  // @ts-ignore
  private arrowKeyUpSub: Subscription;
  // @ts-ignore
  private loadVideoSub: Subscription;
  // @ts-ignore
  private videoLoadingSub: Subscription;
  // @ts-ignore
  private videoPlayingSub: Subscription;

  isVideoWaiting = false;
  extractCoverEvent = new EventEmitter();


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this._currentStep === 1) {
      this.drawRulerBar();
    }
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      title: [null, [Validators.required]],
      level:  [1, [Validators.required]],
      kind: [null, [Validators.required]],
      theme: [null, [Validators.required]],
      cover: [null, [Validators.required]],
      intro: [null, [Validators.required]],
      syntax: [null, [Validators.required]],
    });
    if (this.editId) {

      this.qupeiyinSvc.getItem(this.editId).then(r => {
        console.log(r);
        this.editData = r;
        this.DUBData.video_url = r.url;
        this.isLoadingVideoData = true;
        this.currentStep = 1;

      });
    }
  }
  initStep1() {
    console.log('initStep1');
    this.isLoadingVideoData = true;
    // @ts-ignore
    this.videoDelegate = new VideoDelegate(this.videoElement.nativeElement);

    if (this.videoLoadingSub) {
      this.videoLoadingSub.unsubscribe();
    }
    // @ts-ignore
    this.videoLoadingSub = this.videoDelegate.videoWaiting.subscribe((e) => {
      this.isVideoWaiting = true;
      console.log('waiting');
    });
    if (this.videoPlayingSub) {
      this.videoPlayingSub.unsubscribe();
    }
    // @ts-ignore
    this.videoLoadingSub = this.videoDelegate.videoPlaying.subscribe((e) => {
      if (this.isVideoWaiting) {
        console.log('playing');
        this.isVideoWaiting = false;
      }
    });


    if (this.videoPlayBarPositionSub) {
      this.videoPlayBarPositionSub.unsubscribe();
    }
    // @ts-ignore
    this.videoPlayBarPositionSub = this.videoDelegate.videoPlayBarPosition.subscribe((evt) => {
      this.calcPlayBarPositionByTime(evt.time);
    });

    if (this.videoDataLoadedSub) {
      this.videoDataLoadedSub.unsubscribe();
    }
    // @ts-ignore
    this.videoDataLoadedSub = this.videoDelegate.videoDataLoaded.subscribe(() => {
      console.log('this.videoDelegate.videoDataLoaded');
      // @ts-ignore
      this.DUBData.duration = this.videoDelegate.duration;
      this.DUBData.width = this.videoElement.nativeElement.videoWidth;
      this.DUBData.height = this.videoElement.nativeElement.videoHeight;
      this.timeRangeObj.max = this.videoDelegate.duration;
      // this.dimensions = this.videoDelegate.dimensions;
      // this.timeRangeSelector[1] = this.videoDelegate.duration;
      this.timeRangeSelector = [0, this.videoDelegate.duration];
      this.currentAudioDuration = this.videoDelegate.durationFormatted;
      // if (this.DUBData && this.DUBData.timePointData) {
      //   // this.timePointData = [];
      //   this.DUBData.timePointData.forEach(timeline => {
      //     this.setTimestampPhase(timeline);
      //   });
      // }
      if (this.editData) {
        // @ts-ignore
        this.DUBData.timePointData = this.editData.sents;
        // @ts-ignore
        this.DUBData.cover_url = this.editData.cover_url;
      }

      this.drawToolBar();

    });

    if (this.videoPlayTimestampSub) {
      this.videoPlayTimestampSub.unsubscribe();
    }

    this.videoPlayTimestampSub = this.videoDelegate.videoPlayTimestamp.subscribe((evt: any) => {
      this.currentAudioTime = evt.timeFormat;
      let time = evt.currentTime;


      if (this.isScaleTimeLine) {
        if ( evt.startTime >= this.timeRangeSelector[0]) {
          time = evt.startTime - this.timeRangeSelector[0];
        }
        if (evt.startTime >= this.timeRangeSelector[1]) {
          this.videoDelegate.pause();
          this.videoDelegate.currentTime = evt.startTime; //  - 5 * 60 / 1000;
          this.calcPlayBarPositionByTime(this.timeRangeSelector[0]);
        }
      } else {
        this.calcPlayBarPositionByTime(time);
      }
    });

    if (this.videoPlayEndSub) {
      this.videoPlayEndSub.unsubscribe();
    }
    this.videoPlayEndSub = this.videoDelegate.videoPlayEnd.subscribe(() => {
      this.calcPlayBarPositionByTime(0);
      this.selectHighlightTimePointIndex = -1;
    });
    this.videoDelegate.src = this.DUBData.video_url;

  }
  initStep2() {
    if (!this.isFormMetaDataLoaded) {
      this.isLoadingFormMeta = true;
      this.qupeiyinSvc.loadFormInfo().then(data => {
        console.log('qupeiyinSvc', data);
        const formLevelOptions: any[] = [];
        const formKindOptions: any[] = [];
        const formThemeOptions: any[] = [];
        data.rows.forEach((info: any) => {
          if (info.type === 1) {
            formLevelOptions.push(info);
          } else if (info.type === 2) {
            // const kind = this.validateForm.controls.kind.value.find(v => {
            //   return info.id === v.id;
            // });
            formKindOptions.push({ label: info.name, value: info.id/*, checked: kind && kind.checked ? true : false*/ });
          } else if (info.type === 3) {
            // const theme = this.validateForm.controls.theme.value.find(v => {
            //   return info.id === v.id;
            // });
            formThemeOptions.push({ label: info.name, value: info.id/*, checked: theme && theme.checked ? true : false*/ });
          }
        });

        this.formLevelOptions = formLevelOptions;
        this.formKindOptions = formKindOptions;
        this.formThemeOptions = formThemeOptions;
        this.setFormDefaultValue();
        this.isLoadingFormMeta = false;
        this.isFormMetaDataLoaded = true;
      });

    } else {
     this.setFormDefaultValue();
    }

  }
  lostFocus(evt: any) {
    console.log(evt);
    this.selectHighlightTimePointIndex = -1;
  }
  isInvaildSents() {
    // console.log(this.DUBData.timePointData);
    const zeroSents = this.DUBData.timePointData.filter((s: any) => {
      return s.startTime === s.endTime || !s.text.trim();
    });
    return zeroSents.length > 0;
  }
  setFormDefaultValue() {
    if (this.editData) {
      this.formKindOptions.forEach(k => {
        if (this.editData.kind.includes(k.value)) {
          k.checked = true;
        }
      });
      this.formThemeOptions.forEach(k => {
        if (this.editData.theme.includes(k.value)) {
          k.checked = true;
        }
      });
      // @ts-ignore
      this.validateForm.get('cover').setValue(this.editData.cover_url);
      // @ts-ignore
      this.validateForm.get('title').setValue(this.editData.title);
      // @ts-ignore
      this.validateForm.get('level').setValue(this.editData.level);
      // @ts-ignore
      this.validateForm.get('intro').setValue(this.editData.intro);
      // @ts-ignore
      this.validateForm.get('syntax').setValue(this.editData.syntax);
    }
  }
  drawRulerBar(timeStart?: any, timeEnd?: any) {
    if (!timeStart) {
      timeStart = 0;
    }
    if (!timeEnd) {
      timeEnd = this.videoDelegate.duration;
    }

    const rulerHeight = 80 ;
    const dpr = window.devicePixelRatio || 1;
    const ruleCtx = this.rulerCanvasEl.nativeElement.getContext('2d');

    const w = this.rulerCanvasEl.nativeElement.offsetWidth;
    this.rulerCanvasEl.nativeElement.width = w * dpr;
    this.rulerCanvasEl.nativeElement.height = rulerHeight * dpr;
    this.rulerCanvasEl.nativeElement.style.height = `${rulerHeight}px`;



    // this.ctx.lineWidth = 5;
    const blank = (20 * dpr);
    const keyStep = 5;
    const keyPointNum = Math.ceil(w * dpr  / (keyStep * blank) );
    const t = (timeEnd - timeStart) / keyPointNum;
    let pointCounter = 0;
    ruleCtx.beginPath();
    ruleCtx.font = `${12 * dpr}px arial`;
    for (let i = 0; i < w * dpr; i += blank) {
      let y = 80;

      if ((i / (keyStep * blank) === parseInt(String(i / (keyStep * blank)), 10)) ) {
        // const y = (i / 100 === parseInt(String(i / 100), 10)) ? 100 : 120;
        const lbl = this.videoDelegate.convertTimeToTag(t * pointCounter , false);
        pointCounter += 1;

        const s = ruleCtx.measureText(lbl);
        const lw = s.width;
        // const lh = s.height;
        ruleCtx.fillText(lbl, i - lw / 2, 30 );
        y = 60;
      }
      ruleCtx.moveTo(i, y);
      ruleCtx.lineTo(i, 100);
    }
    ruleCtx.stroke();
  }
  drawToolBar(timeStart?: any, timeEnd?: any) {
    this.drawRulerBar(timeStart, timeEnd);
    if (!timeStart) {
      timeStart = 0;
    }
    if (!timeEnd) {
      timeEnd = this.videoDelegate.duration;
    }

    const dpr = window.devicePixelRatio || 1;


    this.ctx = this.canvasElRef.nativeElement.getContext('2d');
    this.waveWidth = this.canvasElRef.nativeElement.offsetWidth;
    const {width, height} = this.videoDelegate.dimensions;

    const thumbWidth = Math.ceil(this.waveWidth / this.thumbNumbers) ;
    const thumbHeight = (thumbWidth * height / width );
    this.waveHeight = thumbHeight; //  + ruleHeight;


    this.canvasElRef.nativeElement.width = this.waveWidth * dpr;
    this.canvasElRef.nativeElement.height = this.waveHeight * dpr;



    this.canvasElRef.nativeElement.style.height = `${thumbHeight}px`;

    const range = timeEnd - timeStart;
    const thumbTimeOffset = Math.ceil((range * 1000) / this.thumbNumbers);
    let thumbTime = 1;
    let finished = 0;
    for (let i  = 0; i < this.thumbNumbers; i++) {
      const img = new Image();
      // @ts-ignore
      img.idx = i;
      img.addEventListener('load', (e) => {
        const target = e.target as HTMLImageElement;
        // @ts-ignore
        console.log(target.src, target.idx, i, thumbWidth * target.idx);
        // @ts-ignore
        this.ctx.drawImage(target, thumbWidth * target.idx * dpr , 0);
        finished++;
        if (finished === this.thumbNumbers) {
          this.isLoadingVideoData = false;
        }
      });
      img.addEventListener('error', (e) => {
        finished++;
        if (finished === this.thumbNumbers) {
          this.isLoadingVideoData = false;
        }
      });

      // img.src = `${this.DUBData.video_url}?x-oss-process=video/snapshot,t_${thumbTime},m_fast,w_${parseInt(String(thumbWidth * dpr), 10)}`;
      img.src = `${this.DUBData.video_url}?vframe/jpeg/offset/${Math.floor(thumbTime / 1000)}/w/${parseInt(String(thumbWidth * dpr), 10)}`;
      thumbTime += thumbTimeOffset;
    }
    this.timeLine = this.timeLineEl.nativeElement;

   /* if (this.dragMoveBarSub) {
      this.dragMoveBarSub.unsubscribe();
    }
    this.dragMoveBarSub = new DragElement(this.timeLine, {range: [0, this.waveWidth], check:  () => {
        return !this.isPlaying;
      }}).onMove.pipe(
      // sampleTime(100),
    ).subscribe(evt => {
      // this.selectHighlightTimePointIndex = -1;
      const percent = evt.position / this.waveWidth;
      let baseDur = this.videoDelegate.duration;
      let startPoint = 0;
      let currentTime = percent * this.videoDelegate.duration;
      if (this.isScaleTimeLine) {
        baseDur = this.currentTimeRangDuration;
        startPoint = this.timeRangeObj.min;
        currentTime = this.timeRangeObj.min + percent * baseDur;
      }
      this.videoDelegate.currentTime = currentTime;
      this.currentAudioTime = this.videoDelegate.currentTimeFormatted();



      this.calcPlayBarPositionByTime(currentTime);



    });*/

  }
  ctrlBarOnMove = (evt: any) => {
    const percent = evt.position / this.waveWidth;
    let baseDur = this.videoDelegate.duration;
    let startPoint = 0;
    let currentTime = percent * this.videoDelegate.duration;
    if (this.isScaleTimeLine) {
      baseDur = this.currentTimeRangDuration;
      startPoint = this.timeRangeObj.min;
      currentTime = this.timeRangeObj.min + percent * baseDur;
    }
    this.videoDelegate.currentTime = currentTime;
    this.currentAudioTime = this.videoDelegate ? this.videoDelegate.currentTimeFormatted() : '';



    this.calcPlayBarPositionByTime(currentTime);

  }
  ngOnChanges(value: any) {
    console.log('ngOnChanges');
    // if (value.LRCData && !value.LRCData.firstChange) {

    //   this.ngOnInit();
    //   // this.onAudioUploaded({url: this.LRCData.audio_url});
    // }
    // console.log('ngOnChanges', JSON.stringify(value)); // this.ngOnInit();
  }
  blockMoveRange() {
    return (dd: any) => {
      const i = +dd.dragEl.dataset.index;
      if ( i === 0) {
        // let maxX = this.waveWidth - this.timePointData[i].width;
        const cw = this.durationToWidth((this.DUBData.timePointData[i] as any).endTime - (this.DUBData.timePointData[i] as any).startTime );
        let maxX = this.waveWidth - cw;
        if (this.DUBData.timePointData[i + 1]) {
          // maxX = this.timePointData[i + 1].position - this.timePointData[i].width;
          maxX = this.timeToPosition((this.DUBData.timePointData[i + 1] as any).startTime) - cw;
        }
        return [0,  maxX];
      } else if (i === this.DUBData.timePointData.length - 1) {
        const prevPhaseWidth = this.durationToWidth((this.DUBData.timePointData[i - 1] as any).endTime
          - (this.DUBData.timePointData[i - 1] as any).startTime);
        const prevPhaseStart = this.timeToPosition((this.DUBData.timePointData[i - 1] as any).startTime);
        // const minX = this.timePointData[i - 1].position + this.timePointData[i - 1].width;
        const minX = prevPhaseStart + prevPhaseWidth;
        const nextPhaseWidth = this.durationToWidth((this.DUBData.timePointData[i] as any).endTime
          - (this.DUBData.timePointData[i] as any).startTime);
        // const maxX  = this.waveWidth - this.timePointData[i].width;
        const maxX  = this.waveWidth - nextPhaseWidth;
        return [ minX, maxX ];
      } else {
        // const minX = this.timePointData[i - 1].position + this.timePointData[i - 1].width;
        const minX = this.timeToPosition((this.DUBData.timePointData[i - 1] as any).startTime) +
          this.durationToWidth((this.DUBData.timePointData[i - 1] as any).endTime
            - (this.DUBData.timePointData[i - 1] as any).startTime);
        // const maxX  = this.timePointData[i + 1].position - this.timePointData[i].width;
        const maxX  = this.timeToPosition((this.DUBData.timePointData[i + 1] as any).startTime) -
          this.durationToWidth((this.DUBData.timePointData[i] as any).endTime
            - (this.DUBData.timePointData[i] as any).startTime);
        return [ minX, maxX ];
      }
    };
  }
  timeToPosition(time: number) {
      return this.getXPositionByTime(time);
  }
  positionToTime(posX: number) {
    const percent = posX / this.waveWidth;
    const currentTime = percent * this.videoDelegate.duration;
    return currentTime;
    // const posMove = this.getXPositionByTime(currentTime);
  }
  durationToWidth(duration: number) {
    const w = duration * this.waveWidth / this.videoDelegate.duration;
    return w;

  }
  getPhaseRange(item: any) {
    const start = this.timeToPosition(item.startTime);
    const width = this.durationToWidth(item.endTime - item.startTime);
    return {
      start, width, end: start + width
    };
  }

  highlightSelect() {
    return (dd: any) => {
      const idx = +dd.dragEl.dataset.index;
      this.selectHighlightTimePointIndex = idx;
      // 还得把当前控制条，挪到这个位置
      // this.movePlayBarToPos(this.timePointData[idx].position);
      // @ts-ignore
      this.movePlayBarToPos(this.timeToPosition(this.DUBData.timePointData[idx].startTime));
      return true;
    };
  }
  highlightSelect__(evt: any) {
    evt.stopPropagation();
    evt.preventDefault();
    if (evt.target !== evt.currentTarget) {
      return;
    }
      const idx = +evt.target.dataset.index;
      this.selectHighlightTimePointIndex = idx;
      // 还得把当前控制条，挪到这个位置
      // this.movePlayBarToPos(this.timePointData[idx].position);
      this.movePlayBarToPos(this.timeToPosition((this.DUBData.timePointData[idx] as any).startTime));
      return true;
  }


  blockOnMove() {
    return ({dd, position}: {dd: any, position: any}) => {
      const i = +dd.dragEl.dataset.index;
      // if (!position || this.timePointData[i].position === position) {
      //   return;
      // }
      // @ts-ignore
      const dur = this.DUBData.timePointData[i].endTime - this.DUBData.timePointData[i].startTime;
      const t1 = this.positionToTime(position);
      // const t2 = this.positionToTime(position + this.timePointData[i].width);
      const t2 = t1 + dur;
      // @ts-ignore
      this.DUBData.timePointData[i].startTime = t1;
      // this.timePointData[i].startTimeFormatted = this.videoDelegate.convertTimeToTag(t1, false);
      // @ts-ignore
      this.DUBData.timePointData[i].endTime = t2;
      // this.timePointData[i].endTimeFormatted = this.videoDelegate.convertTimeToTag(t2, false);
    };
  }

  convertTimeToTag(t: any) {
    return this.videoDelegate ? this.videoDelegate.convertTimeToTag(t, false) : '';
  }
  movePlayBarToPos (position: any) {
    const currentTime = this.positionToTime(position);
    this.videoDelegate.currentTime = currentTime;
    this.calcPlayBarPositionByTime(currentTime);
    return currentTime;
  }

  blockLeftB4Down() {
    return (dd: any) => {
      const idx = +dd.dragEl.dataset.index;
      this.selectHighlightTimePointIndex = idx;
      // this.movePlayBarToPos(this.timePointData[idx].position);
      // @ts-ignore
      this.movePlayBarToPos(this.timeToPosition(this.DUBData.timePointData[idx].startTime));
      // const currentTime = this.positionToTime(this.timePointData[i].position);
      // this.videoDelegate.currentTime = currentTime;
      // this.calcPlayBarPositionByTime(currentTime);
      // console.log('b4Down', dd.dragEl, idx);
      return true;
    };
  }
  blockLeftRange() {
    const widthPerSec = this.waveWidth / this.videoDelegate.duration;
    return (dd: any) => {
      const i = +dd.dragEl.dataset.index;
      let minX = 0;
      let maxX = this.waveWidth;
      if ( i === 0) {
        const left = parseInt(dd.dragEl.parentElement.style.left, 10);
        // @ts-ignore
        const w = this.durationToWidth(this.DUBData.timePointData[i].endTime - this.DUBData.timePointData[i].startTime);
        // maxX = left + this.timePointData[i].width;
        maxX = left + w;
      } else if (i === this.DUBData.timePointData.length - 1) {
        // const {position, width}  = this.timePointData[this.timePointData.length - 2];
        // minX = position + width;
        const prevItem = this.DUBData.timePointData[this.DUBData.timePointData.length - 2];
        const prevPosInfo = this.getPhaseRange(prevItem);
        minX = prevPosInfo.end;
        const currPosInfo = this.getPhaseRange(this.DUBData.timePointData[i]);
        // maxX  = this.timePointData[i].position + this.timePointData[i].width;
        maxX  = currPosInfo.end;
        // return [ position + width, maxX ];

      } else {
        // minX = this.timePointData[i - 1].position + this.timePointData[i - 1].width;
        const prev = this.getPhaseRange(this.DUBData.timePointData[i - 1]);
        minX = prev.end;
        // maxX  = this.timePointData[i].position + this.timePointData[i].width;
        const curr = this.getPhaseRange(this.DUBData.timePointData[i]);
        maxX  = curr.end;
      }
      return [ minX, maxX/* - widthPerSec */];
    };


  }
  blockLeftMove() {
    // const maxX = this.timePointData[i].position + this.timePointData[i].width;
    return ({dd, position}: {dd: any, position: any}) => {
      const i = +dd.dragEl.dataset.index;
      // if (!position || this.timePointData[i].position === position) {
      //   return;
      // }
      // const time = this.positionToTime(position);

      // this.timePointData[i].width += this.timePointData[i].position - position;
      // this.timePointData[i].position = position;
      const t1 = this.movePlayBarToPos(position);
      // @ts-ignore
      this.DUBData.timePointData[i].startTime = t1;
      // this.timePointData[i].startTimeFormatted = this.videoDelegate.convertTimeToTag(t1, false);
    };
  }
  blockLeftStart() {
    return (dd: DragElement, event: any) => {
      let w = 0;
      // @ts-ignore
      if (dd.dragEl.parentElement.style.left) {
        // @ts-ignore
        w = parseInt(dd.dragEl.parentElement.style.left, 10);
        // const matrix = new DOMMatrix(this.dragEl.style.transform);
        // const w = matrix.m41;
      }
      dd.relX = event.pageX - w;
    };
  }





  blockRightB4Down() {
    return (dd: any) => {
      const idx = +dd.dragEl.dataset.index;
      this.selectHighlightTimePointIndex = idx;
      // this.movePlayBarToPos(this.timePointData[idx].position + this.timePointData[idx].width);
      this.movePlayBarToPos(this.timeToPosition((this.DUBData.timePointData[idx] as any).endTime));
      console.log('blockRightB4Down', dd.dragEl, idx);
      return true;
    };
  }
  blockRightRange() {
    const widthPerSec = this.waveWidth / this.videoDelegate.duration;
    return (dd: any) => {
      const i = +dd.dragEl.dataset.index;
      let minX = 0;
      let maxX = this.waveWidth;
      if ( i === 0) {
        if (this.DUBData.timePointData[i + 1]) {
          // maxX = this.timePointData[i + 1].position; // + this.timePointData[i + 1].width;
          // @ts-ignore
          maxX = this.timeToPosition(this.DUBData.timePointData[i + 1].startTime); // + this.timePointData[i + 1].width;
        }
        const curr = this.getPhaseRange(this.DUBData.timePointData[i]);
        // minX = this.timePointData[i].position;
        minX = curr.start;
      } else if (i === this.DUBData.timePointData.length - 1) {
        // const {position, width}  = this.timePointData[this.timePointData.length - 1];
        const last = this.getPhaseRange(this.DUBData.timePointData[this.DUBData.timePointData.length - 1]);
        // minX = position;
        minX = last.start;
      } else {
        const curr = this.getPhaseRange(this.DUBData.timePointData[i]);
        const next = this.getPhaseRange(this.DUBData.timePointData[i + 1]);
        // minX = this.timePointData[i].position;
        // maxX  = this.timePointData[i + 1].position; // - this.timePointData[i].width ;
        minX  = curr.start ;
        maxX  = next.start;
      }
      return [ minX/* + widthPerSec*/, maxX ];
    };


  }
  blockRightMove() {
    return ({dd, position}: {dd: any, position: any}) => {
      const i = +dd.dragEl.dataset.index;
      // if (!position || this.timePointData[i].position === position) {
      //   return;
      // }
      // const t2 = this.movePlayBarToPos(this.timePointData[i].position + this.timePointData[i].width);
      const t2 = this.positionToTime(position);
      this.movePlayBarToPos(position);
      // @ts-ignore
      this.DUBData.timePointData[i].endTime = t2;
      // this.timePointData[i].endTimeFormatted = this.videoDelegate.convertTimeToTag(t2, false);
    };
  }
  blockRightStart() {
    return (dd: DragElement, event: any) => {
      // @ts-ignore
      const i = +dd.dragEl.dataset.index;
      let w = 0;
      // @ts-ignore
      if (dd.dragEl.parentElement.style.left) {
        // @ts-ignore
        w = parseInt(dd.dragEl.parentElement.style.left, 10) + dd.dragEl.parentElement.offsetWidth;
        // const matrix = new DOMMatrix(this.dragEl.style.transform);
        // const w = matrix.m41;
      }
      dd.relX = event.pageX - w ; // + this.timePointData[i].width;
    };
  }




  ngOnDestroy(): void {
    if (this.videoPlayBarPositionSub) {
      this.videoPlayBarPositionSub.unsubscribe();
    }
    if (this.videoDataLoadedSub) {
      this. videoDataLoadedSub.unsubscribe();
    }
    if (this.videoPlayTimestampSub) {
      this. videoPlayTimestampSub.unsubscribe();
    }
    if (this.videoPlayEndSub) {
      this. videoPlayEndSub.unsubscribe();
    }
    if (this.dragMoveBarSub) {
      this. dragMoveBarSub.unsubscribe();
    }
    if (this.arrowKeyUpSub) {
      this. arrowKeyUpSub.unsubscribe();
    }
    if (this.loadVideoSub) {
      this. loadVideoSub.unsubscribe();
    }

  }

  ngAfterViewInit() {
    return;
    this.timeLine = this.timeLineEl.nativeElement;

    // this.timeLineBar = this.timeLineBarEl.nativeElement;
    this.waveWidth = this.canvasElRef.nativeElement.offsetWidth;
    this.waveHeight = this.canvasElRef.nativeElement.offsetHeight;
    this.ctx = this.canvasElRef.nativeElement.getContext('2d');
    const dpr = window.devicePixelRatio || 1;
    this.canvasElRef.nativeElement.width = this.waveWidth * dpr;
    this.canvasElRef.nativeElement.height = this.waveHeight * dpr;
    this.ctx.scale(dpr, dpr);
    const cb = (e: any) => {
      if (e.code === 'ArrowUp'
        || e.code === 'ArrowDown'
        || e.code === 'ArrowLeft'
        || e.code === 'ArrowRight') {
        e.preventDefault();
        e.stopPropagation();
        // set time point
      }
    };
    document.removeEventListener('keydown', cb );
    document.addEventListener('keydown', cb );
    if (this.arrowKeyUpSub) {
      this.arrowKeyUpSub.unsubscribe();
    }
    this.arrowKeyUpSub = fromEvent<KeyboardEvent>(document, 'keyup')
      .pipe(throttleTime(100) )
      .subscribe((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.code === 'ArrowUp') {
          this.togglePlay();
          this.canvasElRef.nativeElement.focus();
        }
        if (e.code === 'ArrowDown') {

          this.setTimestampPoint();
          this.canvasElRef.nativeElement.focus();
          // set time point
        }
        if (e.code === 'ArrowLeft') {
          if (this.selectHighlightTimePointIndex >= 0) {
            this.updateTimePointData(this.selectHighlightTimePointIndex, -0.05);
          } else {
            this.videoDelegate.currentTime -= 1;
          }
          this.canvasElRef.nativeElement.focus();

        }
        if (e.code === 'ArrowRight') {
          if (this.selectHighlightTimePointIndex >= 0) {
            this.updateTimePointData(this.selectHighlightTimePointIndex, 0.05);
          } else {
            this.videoDelegate.currentTime += 1;
          }
          this.canvasElRef.nativeElement.focus();
        }
      });

    this.ctx.fillStyle = '#0F0';
    if (this.dragMoveBarSub) {
      this.dragMoveBarSub.unsubscribe();
    }
    this.dragMoveBarSub = new DragElement(this.timeLine, {range: [0, this.waveWidth]})
      .onMove.subscribe(evt => {
      this.selectHighlightTimePointIndex = -1;
      const percent = evt.position / this.waveWidth;
      let baseDur = this.videoDelegate.duration;
      let startPoint = 0;
      let currentTime = percent * this.videoDelegate.duration;
      if (this.isScaleTimeLine) {
        baseDur = this.currentTimeRangDuration;
        startPoint = this.timeRangeObj.min;
        currentTime = this.timeRangeObj.min + percent * baseDur;
      }
      this.videoDelegate.currentTime = currentTime;
      console.log('dd', baseDur, this.videoDelegate.currentTime  );
      this.currentAudioTime = this.videoDelegate ? this.videoDelegate.currentTimeFormatted() : '';
      this.calcPlayBarPositionByTime(currentTime);

    });

  }
  get isPlaying() {
    return this.videoDelegate && this.videoDelegate.isPlaying;
  }
  updateTimePointData(index: number, time: number) {
    const p = this.DUBData.timePointData[index] as any;
    if (p) {
      const newTime = p.startTime + time;
      let posTime = newTime;
      if (this.isScaleTimeLine) {
        posTime = posTime - this.timeRangeObj.min;
      }
      this.videoDelegate.currentTime = newTime;
      p.startTime = newTime;
      // p.position = this.calcPlayBarPositionByTime(posTime, true);
      // p.startTimeFormatted = this.videoDelegate.currentTimeFormatted(newTime);
      if (p.startTime > this.videoDelegate.duration) {
        p.warn = true;
      }
    }
  }
  getXPositionByTime(time: number) {
    let base = this.videoDelegate.duration;
    if (this.currentTimeRangDuration) {
      time = time - this.timeRangeObj.min;
      if (time < 0) {
        time = 0;
      }
      base = this.currentTimeRangDuration;
    }
    return ((time / base) * this.waveWidth) ;
  }
  // @ts-ignore
  calcPlayBarPositionByTime(time: number, returnVal = false) {
    // return

    const posMove = this.getXPositionByTime(time);
    // console.log(x);
    const pos = `translateX(${posMove}px)`;
    if (returnVal) {
      return pos;
    }
    this.timeLine.style.transform = pos;

    // 检测 是否有重叠情况
    const idx = this.DUBData.timePointData.findIndex((t: any, index) => {
      // if (index === this.selectHighlightTimePointIndex) {
      //   return false;
      // }
      if (this.isSettingTimeBlockPoint === 'A' && index < this.selectHighlightTimePointIndex) {
        return time < t.endTime;
      }
      if (this.isSettingTimeBlockPoint === 'B' && index > this.selectHighlightTimePointIndex ) {
        return time > t.startTime;
      }
      return time > t.startTime && time < t.endTime;
    });
    if (idx >= 0) {
      this.canSetTimeBlock = false;
    } else {
      this.canSetTimeBlock = true;
    }

    // if (!this.isSettingTimeBlockPoint) {
    //   return;
    // }
    // // const posMove = this.getXPositionByTime(time);
    // const currentLeft = this.timePointData[this.selectHighlightTimePointIndex].position;
    // const currentWidth = this.timePointData[this.selectHighlightTimePointIndex].position;
    // if (posMove <= currentLeft) {
    //   if (!this._tmpPosition) {
    //     this._tmpPosition = currentLeft;
    //     // 交换 a b 点时间
    //     [this.timePointData[this.selectHighlightTimePointIndex].startTime,
    //       this.timePointData[this.selectHighlightTimePointIndex].startTimeFormatted,
    //       this.timePointData[this.selectHighlightTimePointIndex].endTime,
    //       this.timePointData[this.selectHighlightTimePointIndex].endTimeFormatted
    //     ]
    //       = [
    //       null,
    //       null,
    //       this.timePointData[this.selectHighlightTimePointIndex].startTime,
    //       this.timePointData[this.selectHighlightTimePointIndex].startTimeFormatted,
    //     ];
    //   }
    //   this.isSettingTimeBlockPoint = 'A';
    //
    //
    // } else if (this._tmpPosition && posMove >= this._tmpPosition ) {
    //   this.isSettingTimeBlockPoint = 'B';
    //   // 交换 a b 点时间
    //   [this.timePointData[this.selectHighlightTimePointIndex].startTime,
    //     this.timePointData[this.selectHighlightTimePointIndex].startTimeFormatted,
    //     this.timePointData[this.selectHighlightTimePointIndex].endTime,
    //     this.timePointData[this.selectHighlightTimePointIndex].endTimeFormatted
    //   ]
    //     = [
    //     this.timePointData[this.selectHighlightTimePointIndex].endTime,
    //     this.timePointData[this.selectHighlightTimePointIndex].endTimeFormatted,
    //     null,
    //     null
    //   ];
    // }
    // if (this.isSettingTimeBlockPoint === 'B') {
    //   if (this._tmpPosition) {
    //     this.timePointData[this.selectHighlightTimePointIndex].position = this._tmpPosition;
    //     this._tmpPosition = 0;
    //   }
    //   this.timePointData[this.selectHighlightTimePointIndex].width = posMove - currentLeft;
    // }
    //
    // if (this.isSettingTimeBlockPoint === 'A') {
    //   this.timePointData[this.selectHighlightTimePointIndex].position = posMove;
    //   this.timePointData[this.selectHighlightTimePointIndex].width = this._tmpPosition - posMove ;
    // }
  }
  togglePlay(evt?: any) {
    if (evt) {
      evt.target.blur();
    }
    console.log('togglePlayAudio', evt);
    if (this.isPlaying) {
      this.videoDelegate.pause();
      clearInterval(this.timeLineTimer);
      return;
    }

    let t1 = null;
    let t2 = null;
    if (this.isScaleTimeLine
      && (this.videoDelegate.currentTime < this.timeRangeSelector[0]
        || this.videoDelegate.currentTime >= this.timeRangeSelector[1] )) {
      // this.videoDelegate.currentTime = this.timeRangeSelector[0];
      t1 = this.timeRangeSelector[0];
      t2 = this.timeRangeSelector[1];
    }


    if (this.selectHighlightTimePointIndex >= 0) {
      // 刚插入点，还没拖拽出段
      if ((this.DUBData.timePointData[this.selectHighlightTimePointIndex] as any).startTime
        === (this.DUBData.timePointData[this.selectHighlightTimePointIndex] as any).endTime) {
        t1 = (this.DUBData.timePointData[this.selectHighlightTimePointIndex] as any).startTime;
      } else {
        const p = this.DUBData.timePointData[this.selectHighlightTimePointIndex] as any;
        this.videoDelegate.currentTime = p.startTime;
        t1 = p.startTime;
        t2 = p.endTime;
      }
    }
    this.videoDelegate.play(t1, t2).then(() => {
      // this.timeLineTimer = window.setInterval(() => {
      //   if (canSetPoint) {
      //     console.log(audio.currentTime,audioImages[audio.currentTime] )
      //     audioImages.push([audio.currentTime, pointAudio]);
      //     canSetPoint = false;
      //   }
      // this.timeLine.style.width = `${Math.ceil((this.videoDelegate.currentTime / this.videoDelegate.duration) * 100) }%`;
      // }, 32);
    });
  }

  setTimestampPoint(timelineData: any = null, select = false) {
    if (!this.videoDelegate.currentSrc) {
      return;
    }
    let pointTime = this.videoDelegate.currentTime;
    if (!this.isSettingTimeBlockPoint) {
      this.isSettingTimeBlockPoint = 'B';
      // console.log('setTimestampPoint');

      let text = '';
      let newLine = false;
      if (timelineData) {
        pointTime = timelineData.startTime;
        text = timelineData.text;
        newLine = timelineData.newLine;
      }
      let warn = false;
      if (pointTime > this.videoDelegate.duration || pointTime < 0) {
        warn = true;
      }

      const tmpAllTime = this.DUBData.timePointData.map((d: object) => {
        return {...d};
      });
      // 两句起始间隔
      // tmpAllTime.forEach(p => {
      //   p.startTimeFormatted = this.videoDelegate.convertTimeToTag(p.startTime);
      //   const delta =  Math.abs(p.startTime - p.startTime ) * 1000;
      //   if (delta < 2000) {
      //     p.warn = true;
      //   }
      // });

      // if (this.isScaleTimeLine) {
      //   pointTime = pointTime - this.timeRangeObj.min;
      // }
      const pointData = {
        startTime:  pointTime, // this.videoEl.currentTime,
        endTime:  pointTime, // this.videoEl.currentTime,
        // startTimeFormatted: this.videoDelegate.currentTimeFormatted(pointTime),
        text,
        // position: this.getXPositionByTime(pointTime),
        // width: 0,
        warn,
        newLine
      };
      tmpAllTime.push(pointData);
      tmpAllTime.sort((a: any, b: any) => {
        return a.startTime - b.startTime;
      });
      this.DUBData.timePointData.length = 0;
      let createNewPointIndex = -1;
      tmpAllTime.forEach((item, index) => {
        // @ts-ignore
        this.DUBData.timePointData.push(item);
        if (item === pointData) {
          createNewPointIndex = index;
        }
      });


      if (select && createNewPointIndex > -1) {
        this.selectHighlightTimePointIndex = createNewPointIndex;
      }
    } else {
      this.isSettingTimeBlockPoint = null;
      (this.DUBData.timePointData[this.selectHighlightTimePointIndex] as any).endTime = pointTime;
      // this.timePointData[this.selectHighlightTimePointIndex].endTimeFormatted = this.videoDelegate.convertTimeToTag(pointTime, false);
      this.selectHighlightTimePointIndex = -1;



      // this.timePointData.forEach(p => {
      //   p.startTimeFormatted = this.videoDelegate.convertTimeToTag(p.startTime, false);
      //   const delta =  Math.abs(p.startTime - p.endTime ) * 1000;
      //   if (delta < 2000) {
      //     p.warn = true;
      //   }
      // });
      console.log(this.DUBData.timePointData);
    }



  }

  setTimestampPhase(timelineData: any = null, select = false) {
    if (!this.videoDelegate.currentSrc) {
      return;
    }
    let startTime = this.videoDelegate.currentTime;
    let endTime = startTime;
    if (timelineData) {
      startTime = timelineData.startTime;
      endTime = timelineData.endTime;
    }

    const perSecWidth = this.waveWidth / this.videoDelegate.duration;

    const item = {
      text: '',
      // position: Math.ceil(this.getXPositionByTime(pointTime/* - 1*/)),
      startTime: startTime, //  - 1,
      // startTimeFormatted: this.videoDelegate.currentTimeFormatted(pointTime/* - 1*/),
      endTime: endTime, //  + 1,
      // endTimeFormatted: this.videoDelegate.currentTimeFormatted(pointTime/* + 1*/),
      // width: 0, // perSecWidth * 2,
    };
    (this.DUBData.timePointData as any).push(item);
    this.DUBData.timePointData.sort((a: any, b: any) => {
      return a.startTime - b.startTime;
    });
    this.selectHighlightTimePointIndex = this.DUBData.timePointData.findIndex((t) => {
      return t === item;
    });
    this.canSetTimeBlock = false;
  }


  insertTimePoint(index: number) {
    const st =  (this.DUBData.timePointData[index] as any).startTime;
    let et = st + 5;
    const next = this.DUBData.timePointData[index + 1];
    if (next) {
      et = (next as any).startTime;
    }
    const time = st + (et - st) / 2;
    // if (this.isScaleTimeLine) {
    //   time = time + this.timeRangeObj.min;
    // }
    this.videoDelegate.currentTime = time;
    this.setTimestampPoint({
      time,
      data: ''
    });

    // this.timePointData.splice(index + 1, 0, {
    //   time,
    //   timeFormatted: this.videoEl.currentTimeFormatted(time),
    //   data: '',
    //   position: `translateX(${ this.getXPositionByTime(time)}px)`,
    // } );
    this.selectHighlightTimePointIndex = index + 1;
  }
  removeTimePoint(index: number) {
    this.DUBData.timePointData.splice(index, 1);
    this.selectHighlightTimePointIndex = -1;
  }
  selectTimePoint(index: number) {
    this.selectHighlightTimePointIndex = index;
    this.videoDelegate.currentTime = (this.DUBData.timePointData[index] as any).startTime;
  }


  set currentStep (step) {
    if (step === 0) {
      this.modalService.confirm({
        nzTitle: '提醒',
        nzContent: '返回后您已配置的内容将消失，是否确认吗？',
        nzOkText: 'OK',
        nzCancelText: 'Cancel',
        nzOnOk: () => {
          setTimeout(() => {
            this.DUBData = {
              id: 0,
              title: '',
              cover_url: '',
              video_url: '',
              size: 0,
              width: 0,
              height: 0,
              duration: 0,
              timePointData: []
            };
            this._currentStep = step;
          });
        }
      });
    } else if (step === 1) {
      setTimeout(() => {
        this._currentStep = 1;
        this.timeLineEl.nativeElement.style = 'transform: translateX(0px);';
        this.initStep1();
      });
    } else if (step === 2) {
      setTimeout(() => {
        this._currentStep = 2;
        this.initStep2();
      });
    } else {
      this._currentStep = step;
    }
  }
  get currentStep () {
    return this._currentStep;
  }

  goNextStep() {
    this.currentStep += 1;
  }

  goBackStep() {
    this.currentStep -= 1;
  }


  saveUserData() {

    // this.checkNeedBack();

    // const result = [];
    // this.timePointData.forEach(p => {
    //   const t = {...p};
    //
    //   t.data = t.data;
    //   result.push(t);
    // });

    // const parts = JSON.parse(JSON.stringify(this.timePointData)).map(p => {
    //   delete p.position;
    //   delete p.width;
    //   return p
    // });

    // this.DUBData = {
    //   video_url : this.DUBData.video_url,
    //   title: '',
    //   cover_url: '',
    //   parts: this.timePointData
    // };
    // this.editFinished.emit(this.LRCData);
    console.log(this.DUBData);
  }
  checkNeedBack() {
    if (this.isScaleTimeLine) {
      this.restoreTimeLine();
    }
  }
  changePlaybackRate(val: any) {
    this.videoDelegate.playbackRate = val;
  }
  onImageUploadSuccess(evt: any) {

  }

  formatter = (value: number): string =>  {
    if (!this.videoDelegate)  {
      return '';
    }
    return this.videoDelegate.convertTimeToTag(value, false);
  }
  timeRangeAfterChange(value: any) {
    this.timeRangeSelector = value;
  }
  scaleTimeLine() {
    this.videoDelegate.pause();
    this.selectHighlightTimePointIndex = -1;
    this.isScaleTimeLine = true;
    if (!this.timePointDataFull) {
      this.timePointDataFull = this.DUBData.timePointData.map((line: object) => {
        return {...line};
      });
    }

    // this.timeRangeModeConfObj.startTime = this.timeRangeSelector[0];
    // this.timeRangeModeConfObj.endTime = this.timeRangeSelector[0];
    const start = this.timeRangeSelector[0];
    const end = this.timeRangeSelector[1];
    // const sp = start / this.videoDelegate.duration;
    // const ep = end / this.videoDelegate.duration;
    // const sl = sp * this.audioArrayBuffer.byteLength;
    // const el = ep * this.audioArrayBuffer.byteLength;
    // this.currentTimeRangDuration = end - start;
    // this.timeRangeObj.max = end;
    // this.timeRangeObj.min = start;
    // this.calcPlayBarPositionByTime(start);
    //
    // this.timeRangeSelector = [start, end];
    // this.timeRangeEditLines = [];
    // this.timePointData = this.timePointData.filter( (line, idx) => {
    //   if (line.startTime > start && line.startTime < end) {
    //     this.timeRangeEditLines.push(idx);
    //     return true;
    //   }
    //   return false;
    // }).map( line => {
    //   line.position = this.calcPlayBarPositionByTime( line.startTime , true);
    //   return line;
    // });
    // const ab = this.audioArrayBuffer.slice(sl, el);
    // this.drawArrayBuffer(ab);
    this.drawToolBar(start, end);
  }
  restoreTimeLine() {
    this.videoDelegate.pause();
    this.timeRangeObj.max = this.videoDelegate.duration;
    this.timeRangeObj.min = 0;
    this.timeRangeSelector = [0, this.videoDelegate.duration];
    // this.timeRangeSelector[1] = this.videoEl.duration;
    this.selectHighlightTimePointIndex = -1;
    this.isScaleTimeLine = false;
    this.currentTimeRangDuration = this.videoDelegate.duration;
    if (this.timeRangeEditLines.length) {
      const head = this.timePointDataFull.slice(0, Math.min(...this.timeRangeEditLines) );
      const tail = this.timePointDataFull.slice( Math.max(...this.timeRangeEditLines) + 1 );
      // this.timePointData = [...head, ...this.timePointData, ...tail];
      // @ts-ignore
      this.DUBData.timePointData = [...head, ...this.DUBData.timePointData, ...tail].map(line => {
        return {...line};
      }).sort((a, b) => {
        return a.startTime - b.startTime;
      });
    } else {
      // @ts-ignore
      this.DUBData.timePointData = [...this.timePointDataFull, ...this.DUBData.timePointData];
    }
    // @ts-ignore
    this.DUBData.timePointData = this.DUBData.timePointData.map((line: object) => {
      // line.position = this.calcPlayBarPositionByTime(line.startTime, true);
      return {...line};
    });
    this.timePointDataFull = null;
    // const ab = this.audioArrayBuffer.slice(0);
    // this.drawArrayBuffer(ab);
    this.drawToolBar();
  }




  safeUrl(url: string) {
    if (this.videoDelegate && this.videoDelegate.src === url) {
      return;
    }

    if (!url) {
      return;
    }
    console.log('safeUrl', url);
    return this.sanitization.bypassSecurityTrustUrl(url);
  }

  onVideoUploaded(event: any) {
    console.log('onVideoUploaded', event);
    this.DUBData.video_url = event.url;
    this.DUBData.size = event.file.size;
    if (event.inOSS) {
      this.nzMessageService.success('极速妙传');
    }
    // this.DUBData.parts.length = 0;
    this.DUBData.timePointData.length = 0;
    this.goNextStep();
  }
  onVideoUploadFailure() {

  }
  onVideoCoverExtract(event: any) {
    console.log(event);
    const {url} = event;
    this.isLoadingFormMeta = true;
    const osspath = '/accompaniment/cover/';
    const ha = this.DUBData.video_url.lastIndexOf('/') + 1;
    const hb = this.DUBData.video_url.lastIndexOf('.');
    const hashVal = this.DUBData.video_url.substring(ha, hb);
    this.DUBData.cover_url = url + '?t=' + Math.random();
    // @ts-ignore
    this.validateForm.controls.cover.setValue(url);
    this.nzMessageService.success('封面提取成功');
    this.isLoadingFormMeta = false;
    return;
   const params = {file: event, /* type: ResType,*/
      osspath,
      success_cb: (name: string, hash: string, url: string, file: any, inOSS: boolean) => {
        // console.log(2, name, hash, url, file, inOSS);
        this.DUBData.cover_url = '';
        this.DUBData.cover_url = url + '?t=' + Math.random();
        // @ts-ignore
        this.validateForm.controls.cover.setValue(url);
        this.nzMessageService.success('封面提取成功');
        this.isLoadingFormMeta = false;
      },
      error_cb: (s: any, file: any) => {
        // console.log(3, s, file);
        this.nzMessageService.error('封面提取失败');
        this.isLoadingFormMeta = false;
      } };
    // @ts-ignore
    this.resourceSvc.doUpload(params);
  }

  selectVideoFile() {
    this.eventSelectFile.emit();
  }





  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
// @ts-ignore
    const cover_url = this.validateForm.get('cover').value;
    if (!cover_url) {
      // @ts-ignore
      this.validateForm.get('cover').setErrors({required: true});
    } else {
      // @ts-ignore
      this.validateForm.get('cover').clearValidators();
    }

    const kind: any[] = [];
    const theme: any[] = [];
    // @ts-ignore
    const checkedkind = this.validateForm.get('kind').value.filter(c => {
      if (c.checked) {
        kind.push(c.value);
      }
      return c.checked;
    });
    if (checkedkind.length === 0 ) {
      // @ts-ignore
      this.validateForm.get('kind').setErrors({required: true});
    } else {
      // @ts-ignore
      this.validateForm.get('kind').clearValidators();
    }

    // @ts-ignore
    const checkedTheme = this.validateForm.get('theme').value.filter((c: any) => {
      if (c.checked) {
        theme.push(c.value);
      }
      return c.checked;
    });
    if (checkedTheme.length === 0 ) {
      // @ts-ignore
      this.validateForm.get('theme').setErrors({required: true});
    } else {
      // @ts-ignore
      this.validateForm.get('theme').clearValidators();
    }

    if (this.validateForm.status === 'INVALID') {
      return;
    }
    const result = {
      ...this.DUBData,
      // @ts-ignore
      title: this.validateForm.get('title').value,
      cover_url,
      kind,
      theme,
      // @ts-ignore
      level: this.validateForm.controls.level.value,
      // @ts-ignore
      intro: this.validateForm.controls.intro.value,
      // @ts-ignore
      syntax: this.validateForm.controls.syntax.value,
    };
    // this.DUBData.duration = this.videoDelegate.duration;
    // this.DUBData.height = this.videoElement.nativeElement.naturalHeight;
    // this.DUBData.width = this.videoElement.nativeElement.naturalWidth;

    console.log(result);
    this.isLoadingFormMeta = true;
    if (this.editId) {
      result.id = this.editId;
    }
    const school_id = this.user_info.school_id;
    this.qupeiyinSvc.save(school_id, result).then(r => {
      this.isLoadingFormMeta = false;
      this.router.navigate(['/admin/qupeiyin/index']);
    }).catch((e) => {
      this.isLoadingFormMeta = false;
      this.nzMessageService.error('发生错误，请重试！');
    });
  }
}
