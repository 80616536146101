import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import {
    ResourcePushWeekTemplateHomework, ResourcePushWeekTemplateHomeworkLessonSubTypeItems,
    ResourcePushWeekTemplateHomeworkLessonSubTypes,
    ResourcePushWeekTemplateHomeworkLessonSubTypeValues,
    ResourcePushWeekTemplateHomeworkResource,
    ResourcePushWeekTemplateHomeworkTypes,
    ResourcePushWeekTemplateHomeworkTypeValues,
    ResourcePushWeekTemplateLevel3Resource,
} from 'src/app/model/ResourcePushTemplate';
import {SyllabusService} from '../../../../../../services/syllabus.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'app-admin-resource-category-template-homework',
    templateUrl: './homework.component.html',
    animations: [
        trigger('homeworkAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(-20px)'
                }),
                animate('200ms ease-out', style({
                    opacity: 1,
                    transform: 'translateY(0)'
                }))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({
                    opacity: 0,
                    transform: 'translateY(20px)'
                }))
            ])
        ])
    ]
})
export class AdminResourceCategoryTemplateHomeworkComponent implements OnInit {
    @Input() index!: number;
    @Input() homework!: ResourcePushWeekTemplateHomework;
    @Output() homeworkChange = new EventEmitter<ResourcePushWeekTemplateHomework>();

    level1Resources: ResourcePushWeekTemplateHomeworkResource[] = [];
    level2Resources: ResourcePushWeekTemplateHomeworkResource[] = [];
    level3Resources: ResourcePushWeekTemplateHomeworkResource[] = [];
    level3ResourcesMap: Record<number, ResourcePushWeekTemplateHomeworkResource[]> = {};
    level4ResourcesMap: Record<string, ResourcePushWeekTemplateHomeworkResource[]> = {};

    @Output() deleteHomework = new EventEmitter<void>();
    @Output() addHomework = new EventEmitter<void>();

    form!: FormGroup;
    loading = true;

    protected readonly ResourcePushWeekTemplateHomeworkTypes = ResourcePushWeekTemplateHomeworkTypes;
    protected readonly ResourcePushWeekTemplateHomeworkLessonSubTypes = ResourcePushWeekTemplateHomeworkLessonSubTypes;
    protected readonly ResourcePushWeekTemplateHomeworkTypeValues = ResourcePushWeekTemplateHomeworkTypeValues;
    protected readonly ResourcePushWeekTemplateHomeworkLessonSubTypeItems = ResourcePushWeekTemplateHomeworkLessonSubTypeItems;

    constructor(
        private fb: FormBuilder,
        private syllabusService: SyllabusService,
    ) {}

    async ngOnInit() {
        this.initForm();
        this.setupFormListeners();
        const { type, level1ResourceId, level2ResourceId, level3Resources } = this.homework;
        const tasks: Array<Promise<any> | undefined | null | number> = [
            type && this.updateLevel1Resources(type),
            level1ResourceId && this.updateLevel2Resources(level1ResourceId),
            level2ResourceId && this.updateLevel3Resources(),
        ];

        for (const level3Resource of level3Resources || []) {
            switch (type) {
                case ResourcePushWeekTemplateHomeworkTypeValues.CARTOON:
                    tasks.push(this.updateCartoonLevel4Resources(level3Resource.level3ResourceId, level3Resource.level3ResourceType));
                    break;
                case ResourcePushWeekTemplateHomeworkTypeValues.EXERCISE:
                    tasks.push(this.updateExerciseLevel4Resources(level3Resource.level3ResourceId));
                    break;
                case ResourcePushWeekTemplateHomeworkTypeValues.BOOK:
                    // tslint:disable-next-line:no-shadowed-variable
                    for (const level3Resource of this.homework.level3Resources || []) {
                        tasks.push(this.updateBookLevel3Resources(level2ResourceId, level3Resource.level3ResourceType));
                    }
                    tasks.push(this.updateBookLevel4Resources(level3Resource.level3ResourceId, level3Resource.level3ResourceType));
                    break;
                default:
                    break;
            }
        }

        await Promise.all(tasks.filter(Boolean));
        this.loading = false;
    }

    private initForm() {
        this.form = this.fb.group({
            type: [this.homework.type || null, [Validators.required]],
            level1ResourceId: [this.homework.level1ResourceId || null, [Validators.required]],
            level1ResourceName: [this.homework.level1ResourceName || ''],
            level2ResourceId: [this.homework.level2ResourceId || null, [Validators.required]],
            level2ResourceName: [this.homework.level2ResourceName || ''],
            level3ResourceIds: [this.homework.level3ResourceIds || [], [
                () => this.isLevel3ResourceIds() ? Validators.minLength(1) : null,
            ]],
            level3Resources: this.fb.array(
                this.homework.level3Resources?.map(
                    level3Resource => this.createLevel3ResourceForm(level3Resource, this.homework.type)) || [],
                {
                    validators: [() => this.isLevel3ResourceArray() ? Validators.minLength(1) : null]
                }
            )
        });

        this.form.get('type')?.valueChanges.subscribe(() => this.updateLevel3ResourceTypeValidators());
    }

    private updateLevel3ResourceTypeValidators() {
        const type = this.form.get('type')?.value;
        const _updateLevel3ResourceTypeValidators = (validators: ValidatorFn[]) => {
            this.form.get('level3Resources')?.get('level3ResourceType')?.setValidators(validators);
        };
        switch (type) {
            case ResourcePushWeekTemplateHomeworkTypeValues.CARTOON:
                _updateLevel3ResourceTypeValidators([Validators.required]);
                break;
            case ResourcePushWeekTemplateHomeworkTypeValues.EXERCISE:
                _updateLevel3ResourceTypeValidators([]);
                break;
        }
    }

    private naturalSort<T extends { name: string }>(array: Array<T>): Array<T> {
        return array;
        // maybe not need resort the order
        // const collator = new Intl.Collator('zh', {
        //     numeric: true,
        //     sensitivity: 'base'
        // });
        // return array.sort((a, b) => collator.compare(a.name, b.name));
    }

    private createLevel3ResourceForm(level3Resource?: ResourcePushWeekTemplateLevel3Resource, type?: string | null) {
        if (!level3Resource) {
            level3Resource = {
                level3ResourceId: null,
                level3ResourceType: null,
                level4ResourceIds: [],
            };
        }

        const level3ResourceForm = this.fb.group({
            level3ResourceId: [level3Resource.level3ResourceId || null, [Validators.required]],
            level3ResourceType: [
                level3Resource.level3ResourceType || null,
                type === ResourcePushWeekTemplateHomeworkTypeValues.CARTOON ? [Validators.required] : [],
            ],
            level4ResourceIds: [level3Resource.level4ResourceIds || [], [Validators.required, Validators.minLength(1)]]
        });

        if (type) {
            this.setupLevel3ResourceFormListeners(type, level3ResourceForm);
        }

        return level3ResourceForm;
    }


    private async updateLevel1Resources(type?: string) {
        if (!type) {
            this.level1Resources = [];
            return;
        }
        this.level1Resources = await this.syllabusService.getResources(type, 0);
    }

    private async updateLevel2Resources(level1ResourceId?: number) {
        const type = this.form.get('type')!.value;
        if (!level1ResourceId || !type) {
            this.level2Resources = [];
            return;
        }
        this.level2Resources = await this.syllabusService.getResources(type, level1ResourceId);
    }

    private async updateLevel3Resources() {
        const type = this.form.get('type')!.value;
        const level1ResourceId = this.form.get('level1ResourceId')!.value;
        const level2ResourceId = this.form.get('level2ResourceId')!.value;
        if (!level1ResourceId || !level2ResourceId) {
            this.level3Resources = [];
            return;
        }
        switch (type) {
            case ResourcePushWeekTemplateHomeworkTypeValues.CARTOON:
                this.level3Resources = this.naturalSort(await this.syllabusService.getCartoons(level2ResourceId));
                break;
            case ResourcePushWeekTemplateHomeworkTypeValues.VIDEO:
                this.level3Resources = this.naturalSort(await this.syllabusService.getVideos(level2ResourceId));
                break;
            case ResourcePushWeekTemplateHomeworkTypeValues.AUDIO:
                this.level3Resources = this.naturalSort(await this.syllabusService.getAudios(level2ResourceId));
                break;
            case ResourcePushWeekTemplateHomeworkTypeValues.EXERCISE:
                this.level3Resources = this.naturalSort(await this.syllabusService.getExercises(level2ResourceId));
                break;
            default:
                break;
        }
    }

    private setupFormListeners() {
        // 监听表单变化并更新父组件
        this.form.valueChanges.subscribe(value => {
            this.homeworkChange.emit(Object.assign(this.homework, value));
        });

        // 监听类型变化
        this.form.get('type')!.valueChanges.subscribe(async type => {
            const level1ResourceIdControl = this.form.get('level1ResourceId')!;
            level1ResourceIdControl.setValue(null);
            await this.updateLevel1Resources(type);
        });

        // 监听教材变化
        this.form.get('level1ResourceId')!.valueChanges.subscribe(async level1ResourceId => {
            const level2ResourceIdControl = this.form.get('level2ResourceId')!;
            level2ResourceIdControl.setValue(null);
            await this.updateLevel2Resources(level1ResourceId);
        });

        // 监听教材级别变化
        this.form.get('level2ResourceId')!.valueChanges.subscribe(async level2ResourceId => {
            this.form.get('level3ResourceIds')!.setValue([]);
            this.getLevel3ResourcesForm().clear();
            await this.updateLevel3Resources();
        });
    }

    getLevel3ResourcesForm(): FormArray {
        const control = this.form.get('level3Resources');
        if (!(control instanceof FormArray)) {
            throw new Error('level3Resources is not a FormArray');
        }
        return control;
    }

    isLevel3ResourceIds(): boolean {
        const type = this.form?.get('type')?.value;
        return type ? [
            ResourcePushWeekTemplateHomeworkTypeValues.VIDEO,
            ResourcePushWeekTemplateHomeworkTypeValues.AUDIO,
            // ResourcePushWeekTemplateHomeworkTypeValues.DUBBING,
            // ResourcePushWeekTemplateHomeworkTypeValues.BOOK,
        ].includes(type) : false;
    }

    isLevel3ResourceArray(): boolean {
        const type = this.form?.get('type')?.value;
        return type
            ? [
                ResourcePushWeekTemplateHomeworkTypeValues.CARTOON,
                ResourcePushWeekTemplateHomeworkTypeValues.EXERCISE,
                ResourcePushWeekTemplateHomeworkTypeValues.BOOK,
            ].includes(type)
            : false;
    }

    getValidateStatus(control: AbstractControl | null): string {
        if (!control) { return ''; }
        return control.touched ? (control.invalid ? 'error' : 'success') : '';
    }

    getLevel3Resources(level3ResourceControl: AbstractControl): ResourcePushWeekTemplateHomeworkResource[] {
        const type = this.form.get('type')?.value;  
        if (type === ResourcePushWeekTemplateHomeworkTypeValues.BOOK) {
            const level3ResourceType = level3ResourceControl.get('level3ResourceType')?.value;
            return this.level3ResourcesMap[level3ResourceType] || [];
        }
        return this.level3Resources;
    }

    getLevel4Resources(level3ResourceControl: AbstractControl): ResourcePushWeekTemplateHomeworkResource[] {
        const level3ResourceId = level3ResourceControl.get('level3ResourceId')?.value;
        const level3ResourceType = level3ResourceControl.get('level3ResourceType')?.value;
        if (level3ResourceId === undefined || level3ResourceId === null) {
            return [];
        }
        const key = [level3ResourceId, level3ResourceType].filter(Boolean).join(':');
        return this.level4ResourcesMap[key] || [];
    }

    handleAddLevel3ResourceAdd() {
        const type = this.form.get('type')?.value;
        const level3ResourceForm = this.createLevel3ResourceForm(undefined, type);
        this.getLevel3ResourcesForm().push(level3ResourceForm);
    }

    private setupLevel3ResourceFormListeners(type: string, level3ResourceForm: FormGroup) {
        switch (type) {
            case ResourcePushWeekTemplateHomeworkTypeValues.CARTOON:
                const _updateCartoonLevel4Resources = async () => {
                    const level3ResourceId = level3ResourceForm.get('level3ResourceId')?.value;
                    const level3ResourceType = level3ResourceForm.get('level3ResourceType')?.value;
                    console.log('level3ResourceId', level3ResourceId);
                    console.log('level3ResourceType', level3ResourceType);
                    level3ResourceForm.get('level4ResourceIds')?.setValue([]);
                    await this.updateCartoonLevel4Resources(level3ResourceId, level3ResourceType);
                };

                level3ResourceForm.get('level3ResourceType')?.valueChanges.subscribe(_updateCartoonLevel4Resources);
                level3ResourceForm.get('level3ResourceId')?.valueChanges.subscribe(_updateCartoonLevel4Resources);
                break;
            case ResourcePushWeekTemplateHomeworkTypeValues.EXERCISE:
                const _updateExerciseLevel4Resources = async () => {
                    const level3ResourceId = level3ResourceForm.get('level3ResourceId')?.value;
                    level3ResourceForm.get('level4ResourceIds')?.setValue([]);
                    await this.updateExerciseLevel4Resources(level3ResourceId);
                };
                level3ResourceForm.get('level3ResourceId')?.valueChanges.subscribe(_updateExerciseLevel4Resources);
                break;
            case ResourcePushWeekTemplateHomeworkTypeValues.BOOK:
                const _updateBookLevel3Resources = async () => {
                    console.log('updateBookLevel3Resources');
                    const level2ResourceId = this.form.get('level2ResourceId')?.value;
                    const level3ResourceType = level3ResourceForm.get('level3ResourceType')?.value;
                    level3ResourceForm.get('level4ResourceIds')?.setValue([]);
                    level3ResourceForm.get('level3ResourceId')?.setValue(null);
                    await this.updateBookLevel3Resources(level2ResourceId, level3ResourceType);
                };
                level3ResourceForm.get('level3ResourceType')?.valueChanges.subscribe(_updateBookLevel3Resources);
                const _updateBookLevel4Resources = async () => {
                    const level3ResourceId = level3ResourceForm.get('level3ResourceId')?.value;
                    const level3ResourceType = level3ResourceForm.get('level3ResourceType')?.value;
                    level3ResourceForm.get('level4ResourceIds')?.setValue([]);
                    if (level3ResourceId) {
                        if (level3ResourceType) {
                            await this.updateBookLevel4Resources(level3ResourceId, level3ResourceType);
                        }
                    }
                };
                level3ResourceForm.get('level3ResourceId')?.valueChanges.subscribe(_updateBookLevel4Resources);
                break;
            default:
                break;
        }
    }

    private async updateBookLevel3Resources(level2ResourceId?: number | null, level3ResourceType?: number | null) {
        if (!level2ResourceId || !level3ResourceType) {
            return;
        }
        this.level3Resources = this.naturalSort(await this.syllabusService.getBooks(level2ResourceId, level3ResourceType));
        this.level3ResourcesMap[level3ResourceType] = this.level3Resources;
    }

    private async updateBookLevel4Resources(level3ResourceId?: number | null, level3ResourceType?: number | null) {
        if (!level3ResourceId || !level3ResourceType) {
            return;
        }

        const key = [level3ResourceId, level3ResourceType].filter(Boolean).join(':');
        if (this.level4ResourcesMap[key]) {
            return;
        }

        let resources: Array<ResourcePushWeekTemplateHomeworkResource> = [];
        switch (level3ResourceType) {
            case ResourcePushWeekTemplateHomeworkLessonSubTypeValues.CARTOON:
                resources = await this.syllabusService.getBookCoursorwareResourceReadings(level3ResourceId);
                break;
            case ResourcePushWeekTemplateHomeworkLessonSubTypeValues.RECORD_AUDIO:
                resources = await this.syllabusService.getBookCoursorwareResourceRecordAudios(level3ResourceId);
                break;
            default:
                break;
        }

        this.level4ResourcesMap[key] = this.naturalSort(resources);
    }

    private async updateExerciseLevel4Resources(level3ResourceId?: number | null) {
        if (!level3ResourceId) {
            return;
        }
        this.level4ResourcesMap[level3ResourceId] = this.naturalSort(await this.syllabusService.getExerciseItems(level3ResourceId));
    }

    private async updateCartoonLevel4Resources(level3ResourceId?: number | null, level3ResourceType?: number | null) {
        if (!level3ResourceId) {
            return;
        }

        const key = [level3ResourceId, level3ResourceType].filter(Boolean).join(':');
        if (this.level4ResourcesMap[key]) {
            return;
        }

        let resources: ResourcePushWeekTemplateHomeworkResource[] = [];
        switch (level3ResourceType) {
            case ResourcePushWeekTemplateHomeworkLessonSubTypeValues.CARTOON:
                resources = await this.syllabusService.getCoursewareResourceCartoons(level3ResourceId);
                break;
            case ResourcePushWeekTemplateHomeworkLessonSubTypeValues.VIDEO:
                resources = await this.syllabusService.getCoursewareResourceVideos(level3ResourceId);
                break;
            case ResourcePushWeekTemplateHomeworkLessonSubTypeValues.AUDIO:
                resources = await this.syllabusService.getCoursewareResourceAudios(level3ResourceId);
                break;
            case ResourcePushWeekTemplateHomeworkLessonSubTypeValues.EXERCISE:
                resources = await this.syllabusService.getCoursewareResourceExercises(level3ResourceId);
                break;
            case ResourcePushWeekTemplateHomeworkLessonSubTypeValues.RECORD_AUDIO:
                resources = await this.syllabusService.getCoursewareResourceRecordAudios(level3ResourceId);
                break;
            default:
                break;
        }
        this.level4ResourcesMap[key] = this.naturalSort(resources);
    }

    handleDeleteLevel3Resource(level3ResourceIndex: number) {
        this.getLevel3ResourcesForm().removeAt(level3ResourceIndex);
    }

    handleDeleteHomework() {
        this.deleteHomework.emit();
    }

    handleAddHomework() {
        this.addHomework.emit();
    }

    validate() {
        this.form.markAllAsTouched();
        return this.form.valid;
    }

}
