import { Component, Input, TemplateRef } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';




@Component({
  selector: 'book-rel-item-bar',
  template: `
    <div class="flex border bg-white rounded-md p-4 mt-4">
      <div class="flex w-8 h-8 text-gray-400 items-center justify-center">
        <div [class]="icon + ' icon flex-none mr-2'"></div>
      </div>
      <div class="flex-1 text-lg main-text-color">{{ title }}</div>
      <div class="flex-none text leading-8 mr-4 text-slate-400">
        <div *ngIf="status" style="color: #3FA02D;">已上传<span nz-icon nzType="check-circle" nzTheme="fill" style="color: #3FA02D;margin-left: 4px;"></span></div>
        <div *ngIf="!status">未上传</div>
      </div>
      <div class="flex-none text">
        <button nz-button class="" nzType="primary" [nzSize]="'default'"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="link" nzGhost>
          编辑
        </button>
      </div>
    </div>
  `,
  // standalone: true,
  // imports: [
  //   NzButtonModule,
  //   RouterLink,
  //   NgIf
  // ],
  styles: [
    `

      .bandu {
        background-image: url("../../../../../../assets/icon1/bandu.png");
      }

      .dubbing {
        background-image: url("../../../../../../assets/icon1/dubbing.png");
      }

      .game {
        background-image: url("../../../../../../assets/icon1/game.png");
      }

      .listen {
        background-image: url("../../../../../../assets/icon1/listen.png");
      }

      .reading {
        background-image: url("../../../../../../assets/icon1/reading.png");
      }

      .reading2 {
        background-image: url("../../../../../../assets/icon1/reading2.png");
      }

      .sentence {
        background-image: url("../../../../../../assets/icon1/sentence.png");
      }

      .testing {
        background-image: url("../../../../../../assets/icon1/testing.png");
      }

      .watching {
        background-image: url("../../../../../../assets/icon1/watching.png");
      }

      .vocabulary {
        background-image: url("../../../../../../assets/icon1/vocabulary.png");
      }

      .icon {
        background-repeat: no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
      }
    `
  ]
})
export class BookItemBarComponent {

  @Input() icon?: string;
  @Input() title?: string;
  @Input() link?: string;
  @Input() status?: boolean;
}
