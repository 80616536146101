import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../../services/school.service';
import { ClassService } from '../../../../../../services/class.service';
import { UserStatus, UserType } from '../../../../../../model/Const';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CoursewareService, Paragraph } from '../../../../../../services/courseware.service';
import { TwNoticeService } from '../../../../../../services/tw-notice.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { UploadImageWithPreviewComponent } from '../../../../../common/upload-image-with-preview/upload-image-with-preview.component';
import {
  UploadImageWithPreviewV2Component
} from '../../../../../common/upload-image-with-preview-v2/upload-image-with-preview-v2.component';
import { HWResourceService } from '../../../../../../services/huawei-resource.service';
import { WavePlayerV2Component } from '../../../../../common/wave-player-v2/wave-player-v2.component';


@Component({
  selector: 'app-admin-resource-book-item-audio',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class AdminResourceBookItemVideoComponent implements OnInit {

  audio_relate = '';
  book_id = 0;
  res_type = 'video';
  bisType = '';
  pageTitle = '';

  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private coursewareService: CoursewareService,
              private activatedRoute: ActivatedRoute ,
              private twNoticeService: TwNoticeService ,
              private router: Router ,
              private fb: UntypedFormBuilder,
              private nzMessageService: NzMessageService) {
    this.bisType = this.activatedRoute.pathFromRoot[2].snapshot.url[2].path;
    this.activatedRoute.params.subscribe( params => {
      this.book_id = params['item_id'] || 0;

    });
    this.activatedRoute.title.subscribe({
      next: (title) => {
        this.pageTitle = title || '';
      }
    });
  }
  ngOnInit() {
    this.coursewareService.getAssociateResources(this.book_id, this.res_type).then((res: any) => {
      console.log('getAssociateResources', res);
      this.audio_relate = res.map((r: any) => r.resource_id).join(', ');
    }).catch((err: any) => {
      console.log('getAssociateResources', err);
    });
  }
  save(evt: any) {
    const data = this.audio_relate.split(',').filter(a => a.trim()).map(a => parseInt(a.trim(), 10));
    this.coursewareService.setAssociateResources(this.book_id, this.res_type, data).then((res: any) => {
      console.log('setAssociateResources', res);
    }).catch((err: any) => {
      console.log('setAssociateResources', err);
    });

  }

}
