// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LessonResourceService } from '../../../../../../services/lesson-resource.service';
import * as moment from 'moment';


@Component({
  selector: 'app-lesson-resource-audio-theme',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class LessonResourceAudioThemeComponent implements OnInit, OnDestroy {

  public theme_id = 0;
  public top = 0;

  curUserName = '';
  curPhone = '';
  user = null;
  globalRows: any[] = [];
  currentRows: any[] = [];
  themePath: any[] = [];
  kwds = '';
  searchResult: any[] = [];
  isLoadingSearchResult = false;
  showSearchResultPanel = false;
  // @ts-ignore
  public imageObserver: IntersectionObserver;
  public resourceType = 'audio';
  public isSpinning = false;
  isVisible = false;

  modalData = null;
  mediaFlag = '';


  constructor(protected userService: UserService,
              protected lessonResourceService: LessonResourceService,
              protected activatedRoute: ActivatedRoute ,
              protected router: Router ,
              protected nzMessageService: NzMessageService) {

    this.user = this.userService.getUserInfo();



    this.activatedRoute.params.subscribe( params => {
      if (params.theme_id && parseInt(params.theme_id, 10) === params.theme_id * 1) {
        this.theme_id = parseInt(params.theme_id, 10);
      }
    });
    this.activatedRoute.queryParams.subscribe( query => {
      if (query.top && Number(query.top) === query.top * 1) {
       const t = Number(query.top);
       if (t && t > this.top) {
         this.top = t;
       }

      }

    });


  }
  get type() {
    return this.resourceType[0].toUpperCase() + this.resourceType.substr(1);
  }
  isTheme(item) {
    // return typeof item.school_id !== 'undefined' || item.school_id > 0;
    return typeof item.duration === 'undefined';
  }
  themeInfo(item) {
    if (this.resourceType === 'audio') {
      if (item.children && item.children.length > 0) {
        return  `共${item.children.length}个主题`;
      } else {
        return  `共${item.audios}个音频`;
      }
    }
    if (this.resourceType === 'video') {
      if (item.children && item.children.length > 0) {
        return  `共${item.children.length}个主题`;
      } else {
        return  `共${item.videos}个视频`;
      }
    }
  }
  ngOnDestroy() {
    this.destroyLazyImages();
  }
  destroyLazyImages() {
    if (this.imageObserver) {
      this.imageObserver.disconnect();
    }
  }
  initLazyImages() {
    return;
    const lazyloadImages = document.querySelectorAll('.lazy');
    if ('IntersectionObserver' in window) {
      this.imageObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const cover = entry.target as HTMLElement;
            // console.log(cover.classList);
            cover.style.backgroundImage = `url(${cover.dataset.src})`;
            // console.log((image as HTMLElement).dataset.src);
            // image.src = (image as HTMLElement).dataset.src;
            cover.classList.remove('lazy');
            delete cover.dataset.src;
            this.imageObserver.unobserve(cover);
          }
        });
      });

      lazyloadImages.forEach((image) => {
        this.imageObserver.observe(image);
      });
    }

  }
  ngOnInit() {
    this.destroyLazyImages();

    this.loadResourceTheme();

  }

  searchResource() {
    const kwds = this.kwds.trim();
    if (!kwds) {
      return;
    }
    this.isLoadingSearchResult = true;
    this.showSearchResultPanel = true;
    this.lessonResourceService.searchMedia(this.resourceType, kwds).then(sr => {
      this.searchResult = sr.rows;
      console.log('search', sr);
      this.isLoadingSearchResult = false;
    });
  }
  loadResourceTheme() {
    this.isSpinning = true;
    this.lessonResourceService[`get${this.type}Theme`](this.theme_id)
      .then((data) => {
        console.log('data', data);
        this.globalRows = data;
        this.currentRows = data;
        this.isSpinning = false;
        setTimeout(() => {
          this.initLazyImages();
          if (!this.theme_id) {
            const p = document.querySelector('.admin-main');
            if (this.top) {
              p.scrollTop = this.top;
            }
          }

        });
      })
      .catch((err) => {
        this.isSpinning = false;
        this.nzMessageService.error(err && err.message ? err.message : err);
        console.log('err', err);
      });
  }
  loadResourceList(theme_id) {
    this.isSpinning = true;
    this.lessonResourceService[`get${this.type}List`](theme_id)
      .then((data) => {
        console.log('data', data);
        this.currentRows = data.rows.map(r => {
          r.cover = r.cover.replace('http:', '').replace('https:', '');
          r.url = r.url.replace('http:', '').replace('https:', '');
          return r;
        });
        this.isSpinning = false;
        setTimeout(() => {
          this.initLazyImages();
          if (!theme_id) {
            const p = document.querySelector('.admin-main');
            if (this.top) {
              p.scrollTop = this.top;
            }
          }

        });
      })
      .catch((err) => {
        this.isSpinning = false;
        this.nzMessageService.error(err && err.message ? err.message : err);
        console.log('err', err);
      });
  }

  formatDate (d) {
    if (typeof d === 'string') {
      d = new Date(d);
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }


  onBack() {
    let last = this.themePath.pop();
    const pos = this.themePath.length - 1;
    last = this.themePath[pos];
    console.log('last', last);
    if (last) {
      this.currentRows = last.children;
      this.theme_id = last.id;
    } else {
      this.currentRows = JSON.parse(JSON.stringify(this.globalRows));
      this.theme_id = 0;
    }
    const p = document.querySelector('.admin-main');
    if (this.top) {
      p.scrollTop = this.top;
    }
    // const url = `/admin/lesson-resource/${this.resourceType}/theme/`;
    // this.router.navigate([url], { queryParams: { top: this.top }});
  }
  handleModalCancel() {
    this.modalData = null;
    this.mediaFlag = '';
    this.isVisible = false;
  }
  onOpenTheme(item) {
    console.log(this.type, item);
    if (item.suffix) {
      this.modalData = item;
      this.isVisible = true;
      // window.open(item.url, '_blank');
      return;
    }
    const pos = this.themePath.length;
    let parent = null;
    if (pos > 0) {
      parent = this.themePath[pos - 1];
    }
    if (parent && parent.id === item.id) {
      return;
    }
    this.themePath.push(item);

    this.theme_id = item.id;
    if (item.children.length) {
      this.currentRows = item.children;
    } else {
      this.loadResourceList(item.id);
    }
  }
  onGoTo(item) {
    if (item.suffix) {
      window.open(item.url, '_blank');
      return;
    }
    const p = document.querySelector('.admin-main');
    let top = 0;
    if (p) {
      top = p.scrollTop;
    }
    const url = `/admin/lesson-resource/${this.resourceType}/theme/${item.id}`;
    this.router.navigate([url], { queryParams: { top }});
  }
  formatDuration(s) {
    return moment.utc(s * 1000).format('HH:mm:ss');
  }
  closeSearchPanel () {
    if (!this.isLoadingSearchResult) {
      this.showSearchResultPanel = false;
    }
  }
}
